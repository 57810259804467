import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();
export interface Project {
  results: ProjectResponse[];
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
}
export interface ProjectResponse {
  code: string;
  id: number;
  parentId?: number;
  projectGuid: string;
  companyId: number;
  name: string;
  startDate: string;
  endDate: string;
  address: string;
  description: string;
  avatar: string;
  managerName: string;
  managerId: number;
  ownerName: string;
  ownerPhone: string;
  ownerEmail: string;
  isSyncPAMs?: boolean;
  isSyncDocs?: boolean;
}

export interface ProjectProgressResponse {
  actualValue: number;
  planValue: number;
  progressValue: number;
  projectId: number;
  trackerId: number;
}
export interface CreateProjectData {
  companyId: number;
  name: string;
  startDate: string;
  endDate: string;
  address: string;
  description: string;
  Avatar: string;
  ownerName: string;
  ownerPhone: string;
  ownerEmail: string;
  timeData: TimeData;
}
export interface TimeData {
  projectId?: number;
  isSyncPAMs: boolean;
  isSyncDocs: boolean;
  status: number;
  cronExpression: string;
}

class ProjectController {
  public Get = {
    getAllProjects: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Project`, options);
    },
    getProjectConfig: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/ProjectConfig/getProjectConfig/${projectId}`, options);
    },
    getProjects: (companyId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Project/company/${companyId}`, options);
    },
    getCurrentProjects: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Project/current`, options);
    },
  };
  public Post = {
    createProject: (inputValues: CreateProjectData, options?: RequestOptions) => {
      console.log('dữ liệu input create', inputValues);

      return HttpClient.post(`${apiUrl}/api/Project`, inputValues, options);
    },
    CreateProjectConfig: (inputValues: TimeData, options?: RequestOptions) => {
      console.log('dữ liệu input create time', inputValues);
      return HttpClient.post(`${apiUrl}/api/ProjectConfig/createProjectConfig`, inputValues, options);
    },
    createProjectDefaultForPcvm: (projectId: number, options?: RequestOptions) => {
      console.log('Dữ liệu pvcm', projectId);
      return HttpClient.post(`${apiUrl}/api/Pcvm/createProjectDefaultForPcvm/${projectId}`, options);
    },
    syncAllSSNameDocument: (projectId: number, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Pcvm/asyncLinkedDocument/${projectId}`, {}, options);
    },
    syncSSNameDocuments: (projectId: number, selectionSetNames: string[], options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Pcvm/syncDocumentPAMsWithSSName/${projectId}`, selectionSetNames, options);
    },
  };
  public Put = {
    updateProject: (projectId: number, inputValues: CreateProjectData, options?: RequestOptions) => {
      console.log('inputValues project:', inputValues);

      return HttpClient.put(`${apiUrl}/api/Project/${projectId}`, inputValues, options);
    },
    updateProjectConfig: (projectId: number, inputValues: TimeData, options?: RequestOptions) => {
      console.log('inputValues time:', inputValues);

      return HttpClient.put(`${apiUrl}/api/ProjectConfig/updateProjectConfig/${projectId}`, inputValues, options);
    },
    getProjectProgress: (ids: string, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Pcvm/getProjectProgressBar`, ids, options);
    },
    asyncRemainPiping: (projectId: number, body: any, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Pcvm/asyncRemainPiping/${projectId}`, body, options);
    },
    asyncTestPackage: (projectId: number, body: any, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Pcvm/asyncTestPackage/${projectId}`, body, options);
    },
    validatePipingTask: (projectId: number, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Pcvm/validatePipingTaskPAMs/${projectId}`, {}, options);
    },
    validateTeskPackageTaskPAMs: (projectId: number, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Pcvm/validateTeskPackageTaskPAMs/${projectId}`, {}, options);
    },
    validateRemainPipingTaskPAMs: (projectId: number, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Pcvm/validateRemainPipingTaskPAMs/${projectId}`, {}, options);
    },
    asyncPipingTask: (projectId: number, body: any, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Pcvm/asyncPipingTask/${projectId}`, body, options);
    },
  };
  public Delete = {
    deleteProject: (projectId: number) => {
      return HttpClient.delete(`${apiUrl}/api/Project/${projectId}`);
    },
  };
}

export const ProjectService = new ProjectController();
