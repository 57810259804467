import { Observable } from 'rxjs';

import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { PagingResponse } from '@/common/define';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface AttributePagingResponse {
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
  results: AttributeResponse[];
}
export interface TrackerResponse {
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
  results: Tracker[];
}
interface Tracker {
  id: number;
  name: string;
  code: string;
  type: string;
  companyId: number;
  projectId: number;
}
export interface LinkedDocument {
  selectionSetNames: string;
  id: string;
  name: string;
  type: string;
  isPublish: boolean;
  version: string;
  cloudLink: string;
  location: string;
  parentId: string | null;
}
export interface SSNameDocumentResponse {
  projectId: number;
  selectionSetName: string;
  id: string;
  name: string;
  type: string;
  isPublish: boolean;
  version: string;
  cloudLink: string | null;
  location: string;
  parentId: string | null;
  documents: LinkedDocument[];
}
export interface SSNameDocumentPagingResponse extends PagingResponse {
  results: SSNameDocumentResponse[];
}
export interface LinkedDocumentPagingResponse {
  results: LinkedDocument[];
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
}

export enum AttributeValueType {
  Text = 0,
  Number = 2,
  Date = 1,
}

export interface AttributeResponse {
  id: number;
  name: string;
  code: string;
  value: string;
  valueType: AttributeValueType;
  defaultValue: string;
  dateTimeValue: string;
  companyId: number;
}

export interface IssuePagingResponse {
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
  results: IssueResponse[];
}

export interface IssueTrackingAttribute {
  name: string;
  code: string;
  value: string;
}
export interface Area {
  id: number;
  parentId: number;
  name: string;
  code: string;
  description: string;
  projectId: number;
}
export interface workPackage {
  discipline: number;
  areaId: number;
  disciplineName: string;
  id: number;
  parentId: number;
  name: string;
  code: string;
  description: string;
  projectId: number;
}
export interface IssueTarget {
  issuesId: number;
  targetId: number;
  planValue: number;
  actualValue: number;
  costPerValue: number;
  targetDim: TargetDim;
}
export interface TargetDim {
  categoryId: number;
  code: string;
  description: string;
  id: number;
  name: string;
  projectId: number;
  status: number;
  tracker: number;
}

export interface IssueAttribute {
  dailyCapacity: number;
  welderCount: number;
}

export interface IssueResponse {
  id: number;
  subject: string;
  tracker: number;
  category: any;
  testType: string;
  weldingCompleteDate: string;
  punchAClearDate: string;
  readyForTestDate: string;
  isoNo: string;
  spoolNo: string;
  lineNo: string;
  equipmentNo: string;
  planStart: any;
  planEnd: any;
  actualStart: any;
  actualEnd: any;
  selectionSetName: string;
  workPackageId: number;
  areaId: number;
  pipeLineId: any;
  synchronizationId: string;
  displayName: string;
  plannedStartDate: string;
  plannedEndDate: string;
  actualStartDate: string;
  actualEndDate: string;
  parentId: any;
  projectId: number;
  categoryId: any;
  createdOn: string;
  description: string;
  tagVersionName: string;
  notes: string;
  createdBy: any;
  supervisor: any;
  priority: number;
  stars: number;
  status: number;
  type: number;
  estimatedTime: number;
  startDate: string;
  dueDate: string;
  startTime: string;
  attachmentLinks: any[];
  progress: number;
  attributes: AttributeResponse[];
  issueTargets: IssueTarget[];
  issueAttribute: IssueAttribute[];
}

export interface ActivityChartTable {
  key: string;
  discipline: any;
  setName: any;
  planStart: any;
  actStart: any;
  planEnd: any;
  actEnd: any;
  planVolume: any;
  actVolumne: any;
  remain: any;
  unit: any;
}

export interface CreateIssuePayload {
  parentId: number;
  projectId: number;
  categoryId: number;
  subject: string;
  description: string;
  createdById: number;
  supervisorId: number;
  priority: number;
  status: number;
  type: number;
  estimatedTime: number;
  startDate: string;
  dueDate: string;
  attribute: AttributeResponse[];
}

export interface UpdateIssuePayload {
  name: string;
  description: string;
  price: number;
}

export interface CreateLinkedDocuments {
  projectId: number;
  selectionSetName: string;
  documentId: string;
}

export enum IssueSortKey {
  Property = 0,
  Attribute = 1,
  Target = 2,
  TargetPlane = 3,
  TargetActual = 4,
}

export interface ProjectSyncAudit {
  projectId: number;
  syncStatus: number;
  syncType: number;
  createdAt: string;
  startTime: string;
  endTime: string;
  errorMessage: string;
  note: string | null;
}

class IssueController {
  public Get = {
    getIssues: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Issue/project/${projectId}`, options);
    },
    getIssuesByTracker: (projectId: number, trackerId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Issue/issueByTracker/${projectId}/${trackerId}`, options);
    },
    getAttributesByTracker: (trackerId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/AttributeDim/attributeByTrackerId/${trackerId}`, options);
    },
    getTracker: (companyId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/TrackerDim/trackerByCompany/${companyId}`, options);
    },
    exportFile: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Pcvm/export/project/${projectId}`, {
        ...options,
        ...{ headers: { ...options?.headers, accept: '*/*' }, responseType: 'blob' },
      });
    },
    getLinkedDocuments: (projectId: number, options?: RequestOptions): Observable<LinkedDocumentPagingResponse> => {
      return HttpClient.get(`${apiUrl}/SSNameDocument/getLinkedDocumentsByProjectId/${projectId}`, options);
    },
    getFoldersRequest: (projectId: number, options?: RequestOptions): Observable<string[]> => {
      return HttpClient.get(`${apiUrl}/SSNameDocument/getLocationFiles/${projectId}`, options);
    },
    getAllLinkedDocument: (projectId: number, options?: RequestOptions): Observable<LinkedDocumentPagingResponse> => {
      return HttpClient.get(`${apiUrl}/SSNameDocument/getAllLinkedDocumentByProjectId/${projectId}`, options);
    },
    getSSNameDocuments: (
      projectId: number,
      areaId: number,
      options?: RequestOptions,
    ): Observable<SSNameDocumentPagingResponse> => {
      return HttpClient.get(`${apiUrl}/SSNameDocument/getSSNameDocumentsByProjectId/${projectId}/${areaId}`, options);
    },
    exportSSLinkedDocuments: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Pcvm/exportSSNameDocumentFile/${projectId}`, {
        ...options,
        ...{ headers: { ...options?.headers, accept: '*/*' }, responseType: 'blob' },
      });
    },
    syncDocumentPAMs: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Pcvm/syncDocumentPAMs/${projectId}`, options);
    },
  };

  public Post = {
    createIssue: (input: CreateIssuePayload, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Issue`, input, options);
    },
    createProjectSyncAudit: (input: ProjectSyncAudit, options?: RequestOptions) => {
      console.log('Dữ liệu tạo mới service', input);
      return HttpClient.post(`${apiUrl}/api/ProjectSyncAudit`, input, options);
    },
    createLinkedDocuments: (input: CreateLinkedDocuments, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/SSNameDocument`, input, options);
    },
    importFile: (input: any, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Pcvm/importFile`, input, options);
    },
    importSSLinkedDocuments: (projectId: number, input: any, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Pcvm/importSSNameDocumentFile/${projectId}`, input, options);
    },
    syncDocumentPAMsWithSSName: (projectId: number, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Pcvm/syncDocumentPAMsWithSSName/${projectId}`, options);
    },
  };

  public Put = {
    updateIssue: (issueId: number, input: UpdateIssuePayload, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Issue/${issueId}`, input, options);
    },
    updateProjectSyncAudit: (projectId: number, input: ProjectSyncAudit, options?: RequestOptions) => {
      return HttpClient.put(
        `${apiUrl}/api/ProjectSyncAudit/updatebyProjectId/${projectId}?syncType=${input.syncType}`,
        input,
        options,
      );
    },
    addDocumentForSSName: (input: LinkedDocument[], options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/SSNameDocument/addDocumentForSSName`, input, options);
    },
  };

  public Delete = {
    deleteIssue: (issueId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/api/Issue/${issueId}`, options);
    },
    deleteIssues: (issueIds: number[], options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Issue/deletes`, JSON.stringify(issueIds), options);
    },
    deleteIssuesByProject: (projectId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/api/Issue/deleteByProject/${projectId}`, options);
    },
  };
}

export const IssueService = new IssueController();
