import { ReactNode } from 'react';

import { Checkbox, Col, Form, Input, Row, TimePicker, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from './components.module.less';

type GeneralInfoProps = {
  AdditionComponent: ReactNode;
  onChange?: (values: any) => void;
};

export const InvestorInfo = (props: GeneralInfoProps) => {
  const { AdditionComponent } = props;
  const { t } = useTranslation(['projects']);

  const defaultTime = dayjs('17:00', 'HH:mm');

  return (
    <>
      <div className={styles.mainContainer}>
        <Typography.Text style={{ fontWeight: '700', fontSize: '18px', marginBottom: '10px' }}>
          {t('createProject.investorInfo.title')}
        </Typography.Text>
        <Form.Item
          name={'ownerName'}
          label={<Typography.Text strong>{t('createProject.investorInfo.investorName')}</Typography.Text>}
          rules={[{ required: true, message: t('createProject.investorInfo.requireInvestorName') }]}
        >
          <Input placeholder={t('createProject.investorInfo.investorNamePlaceholder')} />
        </Form.Item>
        <Form.Item
          name={'ownerPhone'}
          label={<Typography.Text strong>{t('createProject.investorInfo.investorPhone')}</Typography.Text>}
          rules={[{ required: true, message: t('createProject.investorInfo.requireInvestorPhone') }]}
        >
          <Input placeholder={t('createProject.investorInfo.investorPhonePlaceholder')} />
        </Form.Item>
        <Form.Item
          name={'ownerEmail'}
          label={<Typography.Text strong>{t('createProject.investorInfo.investorEmail')}</Typography.Text>}
          rules={[
            { required: true, message: t('createProject.investorInfo.requireInvestorEmail') },
            { type: 'email', message: t('createProject.investorInfo.emailNotValid') },
          ]}
        >
          <Input placeholder={t('createProject.investorInfo.investorEmailPlaceholder')} />
        </Form.Item>
      </div>
      <div className={styles.syncReservation}>
        <Typography.Text style={{ fontWeight: '700', fontSize: '18px', marginBottom: '10px' }}>
          {t('Sync Reservation')}
        </Typography.Text>
        <Row gutter={[16, 16]} align="middle">
          <Col span={8}>
            <Form.Item name="time" style={{ marginBottom: '0px' }}>
              <TimePicker onChange={props.onChange} defaultValue={defaultTime} format="HH:mm" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="syncPAMS" valuePropName="checked" style={{ marginBottom: '0px' }} initialValue={true}>
              <Checkbox name="syncPAMS">{t('createProject.investorInfo.syncpams')}</Checkbox>
            </Form.Item>
            <Form.Item
              name="documentAllSync"
              valuePropName="checked"
              style={{ marginBottom: '0px' }}
              initialValue={true}
            >
              <Checkbox name="documentAllSync">{t('createProject.investorInfo.docsyncpams')}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {AdditionComponent}
      </div>
    </>
  );
};
