import { useEffect, useState } from 'react';

import { Button, Col, Divider, Form, Popconfirm, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RemovingTrackingIssuesByProject } from '@/common/define';
import { InvestorInfo, ProjectInfo } from '@/pages/CreateProjectPage/components/GeneralInformation/components';
import { CreateProjectData } from '@/services/ProjectService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { issueActions } from '@/store/issue';
import { getLoading } from '@/store/loading';
import { getProjectAvatar, getProjectConfig, getSelectedProject, projectActions } from '@/store/project';
import { getDefaultOrganization } from '@/store/user';

export const GeneralInformation = () => {
  const { t } = useTranslation(['projects']);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const createProjectInformationValue = useAppSelector(getCreateProjectInformationValue());
  const selectedProject = useAppSelector(getSelectedProject());
  const projectConfig = useAppSelector(getProjectConfig());
  const projectAvatar = useAppSelector(getProjectAvatar());
  const defaultOrg = useAppSelector(getDefaultOrganization());
  const isSaving = useAppSelector(getLoading('saveProject'));
  const isReseting = useAppSelector(getLoading(RemovingTrackingIssuesByProject));

  useEffect(() => {
    if (selectedProject) {
      dispatch(projectActions.getProjectConfig(selectedProject.id));
    }
  }, [dispatch, selectedProject]);

  useEffect(() => {
    return () => form.resetFields();
  }, [form]);

  const [projectConfigData, setProjectConfigData] = useState(projectConfig);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  useEffect(() => {
    setProjectConfigData(projectConfig);

    console.log('dữ liệu projectConfig', projectConfig);
    console.log('dữ liệu projectConfigData', projectConfig?.cronExpression);

    form.setFieldValue(
      'time',
      projectConfig && new Date(projectConfig.cronExpression) > new Date(1900, 1, 1)
        ? dayjs(projectConfig.cronExpression)
        : '',
    );

    form.setFieldValue('syncPAMS', projectConfig ? projectConfig.isSyncPAMs : false);
    form.setFieldValue('documentAllSync', projectConfig ? projectConfig.isSyncDocs : false);
  }, [dispatch, projectConfig]);

  const handleSubmit = (values: any) => {
    if (selectedProject) {
      const createProjectData: CreateProjectData = {
        ...selectedProject,
        ...values,
        companyId: defaultOrg?.companyId,
        startDate: values.startDate?.format('YYYY-MM-DD'),
        endDate: values.endDate?.format('YYYY-MM-DD'),
        avatar: projectAvatar,
      };
      console.log('dữ liệu createProjectData values là', values);
      if ((values.syncPAMS || values.documentAllSync) && !values.time?.toDate) {
        form.setFields([
          {
            name: 'time',
            errors: [t('Please enter the time')],
          },
        ]);
        return;
      } else {
        form.setFields([
          {
            name: 'time',
            errors: [],
          },
        ]);
      }

      dispatch(
        projectActions.saveProjectRequest({
          projectId: selectedProject.id,
          input: createProjectData,
        }),
      );

      console.log('dữ liệu bug là', createProjectData);

      var timeSync = values.time?.toDate && values.time?.toDate() ? values.time?.toDate() : undefined;
      var timeData = {
        projectId: selectedProject.id,
        isSyncPAMs: values.syncPAMS,
        isSyncDocs: values.documentAllSync,
        status: 0,
        cronExpression: timeSync,
      };
      if (projectConfig) {
        dispatch(
          projectActions.updateProjectConfig({
            projectId: selectedProject.id,
            input: timeData,
          }),
        );
      } else {
        dispatch(
          projectActions.createProjectConfig({
            projectId: selectedProject.id,
            input: timeData,
          }),
        );
      }
    }
  };

  const handleResetTasks = () => {
    if (selectedProject) {
      dispatch(
        issueActions.deleteIssuesByProjectRequest({
          projectId: selectedProject.id,
          areaId: -1,
          workPackageId: -1,
          trackerId: -1,
        }),
      );
    }
  };

  const AdditionComponent = () => {
    return <Col span={24}></Col>;
  };

  const deleteTask = () => {
    if (selectedProject) {
      dispatch(
        projectActions.deleteProjectRequest({
          projectId: selectedProject.id,
        }),
      );
      navigate('/');
    }
  };

  return (
    <>
      <Space style={{ width: '100%', gap: 10, alignItems: 'center', paddingInline: 30 }}>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('Reset tasks? ')}
        </Typography.Title>
        <Popconfirm title={t('Are you sure to reset all tasks?')} onConfirm={handleResetTasks}>
          <Button danger loading={isReseting}>
            {t('Reset')}
          </Button>
        </Popconfirm>
      </Space>
      <Divider style={{ paddingInline: 30 }} />
      {
        <Form
          {...layout}
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            ...selectedProject,
            startDate: selectedProject ? dayjs(selectedProject.startDate) : null,
            endDate: selectedProject ? dayjs(selectedProject.endDate) : null,
            time: projectConfigData ? dayjs(projectConfigData.cronExpression) : null,
            syncPAMS: projectConfigData ? projectConfigData.isSyncPAMs : false,
            documentAllSync: projectConfigData ? projectConfigData.isSyncDocs : false,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24} lg={24} xl={12}>
              <ProjectInfo />
            </Col>
            <Col span={24} lg={24} xl={12}>
              <InvestorInfo AdditionComponent={<AdditionComponent />} />
            </Col>
            <Col span={24}>
              <div style={{ paddingInline: 30 }}>
                <Button loading={isSaving} type="primary" onClick={form.submit}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      }
      <div style={{ paddingInline: 30 }}>
        <Divider />
        <Popconfirm title={t('Are you sure to delete project?')} onConfirm={deleteTask}>
          <Button danger loading={isReseting}>
            {t('Delete')}
          </Button>
        </Popconfirm>
      </div>
    </>
  );
};
