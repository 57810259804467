import { useEffect } from 'react';

import { CreateUpdateLinkedDocumentModal } from './CreateUpdateLinkedDocumentModal';
import { LinkedDocumentsHeader } from './LinkedDocumentsHeader';
import { LinkedDocumentsTable } from './LinkedDocumentsTable';
import { LinkedDocumentsToolbar } from './LinkedDocumentsToolbar';
import { CreateUpdateLinkedDocumentModalName, largePagingParams } from '@/common/define';
import { getAuthenticated } from '@/store/app';
import { areaActions } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { issueActions } from '@/store/issue';
import { getSSNameDocumentQueryParams, linkedDocumentActions } from '@/store/linkedDocument';
import { getModalVisible } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

export const LinkedDocuments = () => {
  const dispatch = useAppDispatch();

  const auth = useAppSelector(getAuthenticated());
  const params = useAppSelector(getSSNameDocumentQueryParams());
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateLinkedDocumentModalName));
  const selectedProject = useAppSelector(getSelectedProject());

  useEffect(() => {
    if (auth) dispatch(issueActions.getTrackerRequest({ companyId: auth?.companyId, queryParams: largePagingParams }));
  }, []);

  useEffect(() => {
    if (selectedProject) {
      dispatch(
        areaActions.getAreasRequest({
          params: { ...largePagingParams },
          projectId: selectedProject.id,
        }),
      );
      dispatch(
        linkedDocumentActions.getSSNameDocumentsRequest({
          params: {
            ...params,
            trackerId: -1,
          },
          projectId: selectedProject.id,
          areaId: -1,
        }),
      );
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  return (
    <>
      <LinkedDocumentsHeader />
      <LinkedDocumentsToolbar />
      <LinkedDocumentsTable />
      {isModalOpen && <CreateUpdateLinkedDocumentModal />}
    </>
  );
};
