import { Modal } from 'antd';
import { saveAs } from 'file-saver';
import { ofType } from 'redux-observable';
import {
  catchError,
  concat,
  concatMap,
  EMPTY,
  filter,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

import { issueActions } from './issueSlice';
import { showMessage, startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  CreateUpdateTrackingIssueModalName,
  RemovingTrackingIssue,
  RemovingTrackingIssues,
  RemovingTrackingIssuesByProject,
  SavingTrackingIssue,
  defaultPagingParams,
} from '@/common/define';
import { IssueService, ProjectSyncAudit } from '@/services/IssueService';
import { ProjectService } from '@/services/ProjectService';
import { TargetDimService } from '@/services/TargetDimService';
import Utils from '@/utils';

// Nếu bạn đang sử dụng Ant Design

const { confirm } = Modal;
const getIssuesRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.getIssuesRequest.match),
    switchMap(action => {
      const { projectId, queryParams } = action.payload;
      return concat(
        [startLoading({ key: 'GetIssues' })],
        IssueService.Get.getIssues(projectId, { search: queryParams }).pipe(
          mergeMap(issues => {
            return [issueActions.setQueryParams(queryParams), issueActions.setIssues(issues)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [issueActions.setIssues(undefined)];
          }),
        ),
        [stopLoading({ key: 'GetIssues' })],
      );
    }),
  );
};

const getIssuesByTrackerRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.getIssuesByTrackerRequest.match),
    switchMap(action => {
      const { projectId, trackerId, queryParams } = action.payload;
      console.log(queryParams);
      return concat(
        [startLoading({ key: 'GetIssuesByTracker' })],
        IssueService.Get.getIssuesByTracker(projectId, trackerId, { search: queryParams }).pipe(
          mergeMap(issues => {
            return [issueActions.setQueryParams(queryParams), issueActions.setIssues(issues)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [issueActions.setIssues(undefined)];
          }),
        ),
        [stopLoading({ key: 'GetIssuesByTracker' })],
      );
    }),
  );
};

const getAttributesByTrackerRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.getAttributesByTrackerRequest.match),
    switchMap(action => {
      const { trackerId, queryParams } = action.payload;
      return concat(
        [startLoading({ key: 'GetAttributesByTracker' })],
        IssueService.Get.getAttributesByTracker(trackerId, { search: queryParams }).pipe(
          mergeMap(attributes => {
            return [issueActions.setAttribute(attributes)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [issueActions.setAttribute(undefined)];
          }),
        ),
        [stopLoading({ key: 'GetAttributesByTracker' })],
      );
    }),
  );
};

const getTargetsByTrackerRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.getTargetsByTrackerRequest.match),
    switchMap(action => {
      const { queryParams } = action.payload;
      return concat(
        [startLoading({ key: 'GetTargetsByTracker' })],
        TargetDimService.Get.getTargetDimByCondition({ search: queryParams }).pipe(
          mergeMap(targets => {
            return [issueActions.setTargets(targets)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [issueActions.setTargets(undefined)];
          }),
        ),
        [stopLoading({ key: 'GetTargetsByTracker' })],
      );
    }),
  );
};

const getTrackerRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.getTrackerRequest.match),
    switchMap(action => {
      const { companyId, queryParams } = action.payload;
      return concat(
        [startLoading({ key: 'getTrackerRequest' })],
        IssueService.Get.getTracker(companyId, { search: queryParams }).pipe(
          mergeMap(attributes => {
            return [issueActions.setTracker(attributes)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [issueActions.setTracker(undefined)];
          }),
        ),
        [stopLoading({ key: 'getTrackerRequest' })],
      );
    }),
  );
};

const createIssueRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(issueActions.createIssueRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { issue } = action.payload;
      const search = state.issue.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: SavingTrackingIssue })],
        IssueService.Post.createIssue(issue).pipe(
          switchMap(createdIssue => {
            const issueTargetUpdates: { targetId: number; planValue: string; actualValue: string }[] =
              issue.issueTargetUpdates;
            if (!!issueTargetUpdates?.length && createdIssue) {
              return TargetDimService.Put.addTargetToIssue(
                issueTargetUpdates.map(issueTarget => ({
                  ...issueTarget,
                  issueId: createdIssue.id,
                })),
                createdIssue.id,
              ).pipe(
                switchMap(() => {
                  return IssueService.Get.getIssuesByTracker(issue.projectId, issue.trackerId, { search }).pipe(
                    mergeMap(issues => {
                      Utils.successNotification();
                      return [issueActions.setIssues(issues), hideModal({ key: CreateUpdateTrackingIssueModalName })];
                    }),
                    catchError(error => {
                      Utils.errorHandling(error);
                      return [issueActions.setIssues([])];
                    }),
                  );
                }),
                catchError(error => {
                  Utils.errorHandling(error);
                  return [];
                }),
              );
            }
            return IssueService.Get.getIssuesByTracker(issue.projectId, issue.trackerId, { search }).pipe(
              mergeMap(issues => {
                Utils.successNotification();
                return [issueActions.setIssues(issues), hideModal({ key: CreateUpdateTrackingIssueModalName })];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [issueActions.setIssues([])];
              }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingTrackingIssue })],
      );
    }),
  );
};

const updateIssueRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(issueActions.updateIssueRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { issueId, issue } = action.payload;
      const { selectedProject } = state.project;
      const search = state.issue.queryParams || defaultPagingParams;
      const updateData = {
        ...issue,
        issueTargetUpdates: issue.issueTargetUpdates
          ? issue.issueTargetUpdates.map(
              (issueTarget: { targetId: number; planValue: string; actualValue: string }) => ({
                targetId: issueTarget.targetId,
                value: issueTarget.planValue,
              }),
            )
          : undefined,
      };
      if (!selectedProject) return [];
      return concat(
        [startLoading({ key: SavingTrackingIssue })],
        IssueService.Put.updateIssue(issueId, updateData).pipe(
          switchMap(() => {
            const issueTargetsUpdate: { targetId: number; planValue: string; actualValue: string }[] =
              issue.issueTargetUpdates;

            if (!!issueTargetsUpdate?.length) {
              return TargetDimService.Put.logTargetsToIssue(
                {
                  logIssueTargetDTOs: issueTargetsUpdate.map(issueTarget => ({
                    targetId: issueTarget.targetId,
                    actualValue: issueTarget.actualValue,
                  })),
                },
                issueId,
              ).pipe(
                switchMap(() => {
                  return IssueService.Get.getIssuesByTracker(selectedProject.id, issue.trackerId, { search }).pipe(
                    mergeMap(issues => {
                      Utils.successNotification();
                      return [issueActions.setIssues(issues), hideModal({ key: CreateUpdateTrackingIssueModalName })];
                    }),
                    catchError(error => {
                      Utils.errorHandling(error);
                      return [issueActions.setIssues([])];
                    }),
                  );
                }),
                catchError(error => {
                  Utils.errorHandling(error);
                  return [];
                }),
              );
            }
            return IssueService.Get.getIssuesByTracker(selectedProject.id, issue.trackerId, { search }).pipe(
              mergeMap(issues => {
                Utils.successNotification();
                return [issueActions.setIssues(issues), hideModal({ key: CreateUpdateTrackingIssueModalName })];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [issueActions.setIssues([])];
              }),
            );
          }),
          catchError(error => {
            console.log(error);
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingTrackingIssue })],
      );
    }),
  );
};

const importFileRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(issueActions.importFileRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, importType, file } = action.payload;
      const data = new FormData();
      data.append('file', file);
      const search = state.issue.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: 'ImportingIssue' })],
        IssueService.Post.importFile(data, { search: { projectId, importType } }).pipe(
          switchMap(() => {
            return IssueService.Get.getIssuesByTracker(projectId, state.issue.trackerId ?? -1, { search }).pipe(
              map(issues => {
                Utils.successNotification(importType === 1 ? 'Updated successfully.' : 'Imported successfully.');
                return issueActions.setIssues(issues);
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [issueActions.setIssues([])];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: 'ImportingIssue' })],
      );
    }),
  );
};

const exportFileRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(issueActions.exportFileRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, trackerId, areaId, workpackageId } = action.payload;
      const search = state.issue.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: 'ExportingIssue' })],
        IssueService.Get.exportFile(projectId, { search: { trackerId, areaId, workpackageId } }).pipe(
          switchMap(() => {
            return IssueService.Get.getIssuesByTracker(projectId, state.issue.trackerId ?? -1, { search }).pipe(
              map(issues => {
                Utils.successNotification('Export successfully.');
                return issueActions.setIssues(issues);
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [issueActions.setIssues([])];
              }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'ExportingIssue' })],
      );
    }),
  );
};

const deleteIssueRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(issueActions.deleteIssueRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { issueId, projectId, trackerId } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingTrackingIssue })],
        IssueService.Delete.deleteIssue(issueId).pipe(
          switchMap(() => {
            return IssueService.Get.getIssuesByTracker(projectId, trackerId, { search }).pipe(
              mergeMap(issuesResult => {
                Utils.successNotification('Removed successfully');
                return [
                  issueActions.setIssues(issuesResult),
                  issueActions.setSelectedIssue(undefined),
                  issueActions.setQueryParams(search),
                  hideModal({ key: CreateUpdateTrackingIssueModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [issueActions.setIssues(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingTrackingIssue })],
      );
    }),
  );
};

const deleteIssuesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(issueActions.deleteIssuesRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { issueIds, projectId, trackerId } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingTrackingIssues })],
        IssueService.Delete.deleteIssues(issueIds).pipe(
          switchMap(() => {
            return IssueService.Get.getIssuesByTracker(projectId, trackerId, { search }).pipe(
              mergeMap(issuesResult => {
                Utils.successNotification('Removed successfully');
                return [
                  issueActions.setIssues(issuesResult),
                  issueActions.setSelectedIssue(undefined),
                  hideModal({ key: CreateUpdateTrackingIssueModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [issueActions.setIssues(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingTrackingIssues })],
      );
    }),
  );
};

const deleteIssuesByProjectRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(issueActions.deleteIssuesByProjectRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, ...rest } = action.payload;
      const queryParams = state.issue.queryParams;

      let deleteOptions: any = {};
      for (const [key, value] of Object.entries(rest)) {
        console.log([key, value]);
        if (value) {
          deleteOptions[key] = value;
        }
      }

      const search = {
        ...defaultPagingParams,
        ...queryParams,
        page: 1,
        areaId: rest.areaId || queryParams.areaId || undefined,
        workPackageId: rest.workPackageId || queryParams.workPackageId || undefined,
      };
      return concat(
        [startLoading({ key: RemovingTrackingIssuesByProject })],
        IssueService.Delete.deleteIssuesByProject(projectId, { search: deleteOptions }).pipe(
          switchMap(() => {
            if (!rest.trackerId) {
              Utils.successNotification('Removed successfully');
              return [];
            }
            return IssueService.Get.getIssuesByTracker(projectId, rest.trackerId, { search }).pipe(
              mergeMap(issuesResult => {
                Utils.successNotification('Removed successfully');
                return [
                  issueActions.setIssues(issuesResult),
                  issueActions.setSelectedIssue(undefined),
                  hideModal({ key: CreateUpdateTrackingIssueModalName }),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [issueActions.setIssues(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingTrackingIssuesByProject })],
      );
    }),
  );
};
// call api validate

// Từ danh sách trả về (danh sách thông báo lỗi, danh sách Data hợp lệ)
//   1. Nếu có lỗi thì hiển thị thông báo (***)
//   2. Nếu không lỗi thì call api Sync (truyền danh sách hợp lệ vào api)

// (***)
//  1. Trả về file lỗi txt
//  2. Nếu user chọn YES (tiếp tục đồng bộ) (truyền danh sách hợp lệ vào api)
//  3. Nếu user chọn No thì Done

const asyncPipingTaskRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.asyncPipingTaskRequest.match),
    switchMap(action => {
      const { projectId, body, trackerId, errorData } = action.payload;
      return concat(
        [startLoading({ key: 'asyncPiping' })],
        ProjectService.Put.asyncPipingTask(projectId, body).pipe(
          mergeMap(res => {
            let count = Number(res);
            const auditData: ProjectSyncAudit = {
              projectId,
              syncStatus: count > 0 && body.length !== count ? 2 : errorData.length > 0 ? 3 : 1,
              syncType: 1,
              createdAt: new Date().toISOString(),
              startTime: new Date().toISOString(),
              endTime: new Date().toISOString(),
              errorMessage: '',
              note: count > 0 ? `Synced ${count} task(s)` : 'No tasks to sync',
            };
            if (count > 0) {
              Utils.successNotification(`Synced Piping Task successfully with ${count} task(s)`);
              return [
                issueActions.setSyncPipingTaskStatus({
                  status: 1,
                  message: `Synced Piping Task successfully with ${count} task(s)`,
                  syncStatus: auditData.syncStatus,
                }),
                issueActions.getIssuesByTrackerRequest({ projectId, trackerId, queryParams: defaultPagingParams }),
                //todo: gọi API dispatch update audit với status =1
              ];
            } else {
              console.log('error', auditData);
              Utils.successNotification('Do not have any synced Piping Task');

              return [
                issueActions.setSyncPipingTaskStatus({
                  status: 1,
                  message: 'Do not have any synced Piping Task',
                  syncStatus: auditData.syncStatus,
                }),
                issueActions.getIssuesByTrackerRequest({ projectId, trackerId, queryParams: defaultPagingParams }),
                // todo: gọi API dispatch update audit với status =3
              ];
            }
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [issueActions.setSyncPipingTaskStatus({ status: 2, message: 'Sync failed', syncStatus: 2 })];
          }),
        ),
        [stopLoading({ key: 'asyncPiping' })],
      );
    }),
  );
};

const asyncRemainPipingRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.asyncRemainPipingRequest.match),
    switchMap(action => {
      const { projectId, body, trackerId, errorData } = action.payload;
      return concat(
        [startLoading({ key: 'asyncRemain' })],
        ProjectService.Put.asyncRemainPiping(projectId, body).pipe(
          mergeMap(res => {
            let count = Number(res);
            const auditData: ProjectSyncAudit = {
              projectId,
              syncStatus: count > 0 && body.length !== count ? 2 : errorData.length > 0 ? 3 : 1,
              syncType: 1,
              createdAt: new Date().toISOString(),
              startTime: new Date().toISOString(),
              endTime: new Date().toISOString(),
              errorMessage: '',
              note: count > 0 ? `Synced Remain Piping with ${count} task(s)` : 'No tasks to sync',
            };
            console.log('remain', body.length, errorData.length);

            if (count > 0) {
              Utils.successNotification(`Synced Remain Piping successfully with ${count} task(s)`);
              return [
                issueActions.setSyncRemainPipingTaskStatus({
                  status: 1,
                  message: `Synced Remain Piping successfully with ${count} task(s)`,
                  syncStatus: auditData.syncStatus,
                }),
                issueActions.getIssuesByTrackerRequest({ projectId, trackerId, queryParams: defaultPagingParams }),
                //todo: gọi API dispatch update audit với status =1
              ];
            } else {
              Utils.successNotification('Do not have any Synced Remain Piping');
              return [
                issueActions.setSyncRemainPipingTaskStatus({
                  status: 1,
                  message: 'Do not have any Synced Remain Piping',
                  syncStatus: auditData.syncStatus,
                }),
                issueActions.getIssuesByTrackerRequest({ projectId, trackerId, queryParams: defaultPagingParams }),
                //todo: gọi API dispatch update audit với status =3
              ];
            }
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [issueActions.setSyncRemainPipingTaskStatus({ status: 2, message: 'Sync failed', syncStatus: 2 })];
          }),
        ),
        [stopLoading({ key: 'asyncRemain' })],
      );
    }),
  );
};

const asyncTestPackageRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.asyncTestPackageRequest.match),
    switchMap(action => {
      const { projectId, body, trackerId, errorData } = action.payload;
      return concat(
        [startLoading({ key: 'asyncTestPackage' })],
        ProjectService.Put.asyncTestPackage(projectId, body).pipe(
          mergeMap(res => {
            let count = Number(res);

            console.log('count package', count);

            const auditData: ProjectSyncAudit = {
              projectId,
              syncStatus: count > 0 && body.length !== count ? 2 : errorData.length > 0 ? 3 : 1,
              syncType: 1,
              createdAt: new Date().toISOString(),
              startTime: new Date().toISOString(),
              endTime: new Date().toISOString(),
              errorMessage: '',
              note: count > 0 ? `Synced Test Package with ${count} task(s)` : 'No tasks to sync',
            };

            console.log('testpakage', body.length, errorData.length);
            if (count > 0) {
              Utils.successNotification(`Synced Test Package successfully with ${count} task(s)`);
              return [
                issueActions.setSyncTestPackageTaskStatus({
                  status: 1,
                  message: `Synced Test Package successfully with ${count} task(s)`,
                  syncStatus: auditData.syncStatus,
                }),
                issueActions.getIssuesByTrackerRequest({ projectId, trackerId, queryParams: defaultPagingParams }),
              ];
            } else {
              Utils.successNotification('Do not have any Synced Test Package');
              return [
                issueActions.setSyncTestPackageTaskStatus({
                  status: 1,
                  message: 'Do not have any Synced Test Package',
                  syncStatus: auditData.syncStatus,
                }),
                issueActions.getIssuesByTrackerRequest({ projectId, trackerId, queryParams: defaultPagingParams }),
              ];
            }
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [issueActions.setSyncTestPackageTaskStatus({ status: 2, message: 'Sync failed', syncStatus: 2 })];
          }),
        ),
        [stopLoading({ key: 'asyncTestPackage' })],
      );
    }),
  );
};

const createProjectSyncAudit$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.createProjectSyncAuditRequest.match),
    concatMap(action => {
      const { input } = action.payload;
      console.log('createProjectSyncAudit đầu vào trong epic', input);
      return from(IssueService.Post.createProjectSyncAudit(input)).pipe(
        mergeMap(res => {
          return of(issueActions.setProjectSyncAudit(res));
        }),
        catchError(error => {
          Utils.errorHandling(error);
          return EMPTY;
        }),
      );
    }),
  );
};

const updateProjectSyncAudit$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.updateProjectSyncAuditRequest.match),
    concatMap(action => {
      const { projectId, auditData } = action.payload;

      console.log('Dữ liệu gửi lên API để update:', auditData);

      return from(IssueService.Put.updateProjectSyncAudit(projectId, auditData)).pipe(
        mergeMap(res => {
          console.log('Kết quả trả về từ API sau khi update:', res);
          return of(issueActions.setProjectSyncAudit(res));
        }),
        catchError(error => {
          console.error('Lỗi khi gọi API updateProjectSyncAudit:', error);
          Utils.errorHandling(error);
          return EMPTY;
        }),
      );
    }),
  );
};

const validatePipingTaskPAMs$: RootEpic = action$ => {
  return action$.pipe(
    filter(issueActions.validatePipingRequest.match),
    switchMap(action => {
      const { projectId } = action.payload;
      console.log(projectId);
      return concat(
        of(startLoading({ key: 'validatePipingTask' })),
        ProjectService.Put.validatePipingTask(projectId).pipe(
          mergeMap(response => {
            return [];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        of(stopLoading({ key: 'validatePipingTask' })),
      );
    }),
  );
};

//[nam_do] [2024-08-20] sửa logic gọi API đồng bộ the logic mới
const syncPAMSRequest$: RootEpic = action$ => {
  return action$.pipe(
    // call api validate

    // Từ danh sách trả về (danh sách thông báo lỗi, danh sách Data hợp lệ)
    //   1. Nếu có lỗi thì hiển thị thông báo (***)
    //   2. Nếu không lỗi thì call api Sync (truyền danh sách hợp lệ vào api)

    // (***)
    //  1. Trả về file lỗi txt
    //  2. Nếu user chọn YES (tiếp tục đồng bộ) (truyền danh sách hợp lệ vào api)
    //  3. Nếu user chọn No thì Done

    filter(issueActions.syncPAMSRequest.match),
    switchMap(action => {
      const {
        projectId,
        trackerId,
        PipingTaskDataSync,
        RemainPipingDataSync,
        TestPackageDataSync,
        PipingTaskError,
        RemainPipingError,
        TestPackageError,
      } = action.payload;
      console.log(PipingTaskDataSync);
      return concat(
        of(startLoading({ key: 'syncPams' })),
        [
          issueActions.setSyncPipingTaskStatus({ status: 0, message: `Syncing Piping Task...`, syncStatus: 0 }),
          issueActions.asyncPipingTaskRequest({
            projectId,
            trackerId,
            body: PipingTaskDataSync,
            errorData: PipingTaskError,
          }),
        ],
        [
          issueActions.setSyncRemainPipingTaskStatus({ status: 0, message: 'Syncing Piping Remain...', syncStatus: 0 }),
          issueActions.asyncRemainPipingRequest({
            projectId,
            trackerId,
            body: RemainPipingDataSync,
            errorData: RemainPipingError,
          }),
        ],
        [
          issueActions.setSyncTestPackageTaskStatus({
            status: 0,
            message: 'Syncing Piping Test Package...',
            syncStatus: 0,
          }),
          issueActions.asyncTestPackageRequest({
            projectId,
            trackerId,
            body: TestPackageDataSync,
            errorData: TestPackageError,
          }),
        ],
        [stopLoading({ key: 'syncPams' })],
      );
    }),
  );
};

export const issueEpics = [
  updateProjectSyncAudit$,
  createProjectSyncAudit$,
  getIssuesRequest$,
  getIssuesByTrackerRequest$,
  getAttributesByTrackerRequest$,
  createIssueRequest$,
  updateIssueRequest$,
  importFileRequest$,
  exportFileRequest$,
  getTrackerRequest$,
  deleteIssueRequest$,
  deleteIssuesRequest$,
  getTargetsByTrackerRequest$,
  deleteIssuesByProjectRequest$,
  asyncPipingTaskRequest$,
  asyncRemainPipingRequest$,
  asyncTestPackageRequest$,
  syncPAMSRequest$,
  validatePipingTaskPAMs$,
];
