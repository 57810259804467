import { MenuProps, UploadFile } from 'antd';
import { JwtPayload } from 'jwt-decode';

import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface LoginInput {
  username: string;
  password: string;
  captchaId?: string;
  captcha?: string;
  remember: boolean;
}

export interface LoginPayload {
  grant_type: string;
  scope: string;
  username: string;
  password: string;
  client_id: string;
  client_secret?: string;
}

export interface JwtDecoded extends JwtPayload {
  profile: string;
  role: string[];
  orgRoles: string[];
  orgId: string;
  CompanyId: number;
}

export const LOGO = `${apiUrl}/Projects/Images/logo/Logo-W.png`;
export const Installation = `${apiUrl}/Projects/Images/installation/installation.exe`;

export const NavbarHeight = 70;
export const LeftPanelWidth = 240;

export const formatDateDisplay = 'YYYY/MM/DD';
export const APIDateFormat = 'YYYY/MM/DD HH:mm:ss A';
export const invalidDateStrings = ['0001-01-01', '0001-01-01T00:00:00'];

export interface Project {
  id: string;
  external_id: number;
  title: string;
  address: string;
  status?: ProjectStatus;
  photoUrl?: string;
}

export enum ProjectStatus {
  BIDDING = 'bidding',
  COMPLETED = 'completed',
  EXECUTING = 'executing',
}

export const persitConfigKey = 'persitConfig';

export type MenuItem = Required<MenuProps>['items'][number];

/**
 * copy icon svg vào thu mục icons trong public
 * sau đó thêm enum là tên của icon svg vào đây để sử dụng
 */
enum IconSvgEnum {
  bidding,
  contract,
  prepareConstruction,
}

/**
 * copy icon svg vào thu mục icons trong public
 * sau đó thêm enum là tên của icon svg vào IconSvgEnum trong common/define.ts để sử dụng
 */
export type IconSvgType = keyof typeof IconSvgEnum;

export interface ProjectMemberType {
  key: string;
  name: string;
  role: string[];
}

export interface CheckboxType {
  key: string;
  label: string;
  value: string;
}

export interface ProjectInformationValue {
  name: string;
  startDate: Date;
  endDate: string;
  address: string;
  description: string;
  avatar: UploadFile | null;
  ownerName: string;
  ownerPhone: string;
  ownerEmail: string;
  timeData: {
    projectId?: number;
    isSyncPAMs: boolean;
    isSyncDocs: boolean;
    status: number;
    cronExpression: Date;
  };
}

export interface MenuInfo {
  key: string;
  keyPath: string[];
  /** @deprecated This will not support in future. You should avoid to use this */
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

export type ViewState = 'List' | 'Gantt' | 'Kanban';

export interface PagingResponse {
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
  // results: any[];
}

export const defaultPagingParams = {
  paging: true,
  page: 1,
  pageSize: 20,
};

export const largePagingParams = {
  paging: true,
  page: 1,
  pageSize: 2000,
};

export const CreateUpdateAreaModalName = 'CreateUpdateAreaModal';
export const GettingAreaList = 'GettingAreaList';
export const SavingArea = 'SavingArea';
export const RemovingArea = 'RemovingArea';
export const RemovingAllAreas = 'RemovingAllAreas';
export const ImportingAreas = 'ImportingAreas';
export const ExportingAreas = 'ExportingAreas';

export const CreateUpdateWorkPackageModalName = 'CreateUpdateWorkPackageModal';
export const GettingWorkPackageList = 'GettingWorkPackageList';
export const SavingWorkPackage = 'SavingWorkPackage';
export const RemovingWorkPackage = 'RemovingWorkPackage';
export const RemovingAllWorkPackages = 'RemovingAllWorkPackages';
export const ImportingWorkPackages = 'ImportingWorkPackages';
export const ExportingWorkPackages = 'ExportingWorkPackages';

export const CreateUpdateDocumentModalName = 'CreateUpdateDocumentModal';
export const GettingDocumentList = 'GettingDocumentList';
export const SavingDocument = 'SavingDocument';
export const RemovingDocument = 'RemovingDocument';
export const RemovingDocuments = 'RemovingDocuments';
export const UploadingDocument = 'UploadingDocument';
export const DownloadingDocument = 'DownloadingDocument';
export const CreateUpdateFolderModalName = 'CreateUpdateFolderModalName';
export const SavingLabel = 'SavingLabel';

export const CreateUpdateLinkedDocumentModalName = 'CreateUpdateLinkedDocumentModal';
export const AddDocumentModalName = 'AddDocumentModalName';
export const GettingLinkedDocumentList = 'GettingLinkedDocumentList';
export const GetFoldersRequest = 'GetFoldersRequest';
export const GettingSSNameDocumentList = 'GettingSSNameDocumentList';
export const SavingLinkedDocument = 'SavingLinkedDocument';
export const RemovingLinkedDocument = 'RemovingLinkedDocument';
export const UploadingLinkedDocument = 'UploadingLinkedDocument';
export const DownloadingLinkedDocument = 'DownloadingLinkedDocument';
export const importLinkedDocument = 'importLinkedDocument';
export const synchronizingAllSSNameDocument = 'synchronizingAllSSNameDocument';
export const synchronizingSSNameDocuments = 'synchronizingSSNameDocuments';

export const CreateUpdateTrackingIssueModalName = 'CreateUpdateTrackingIssueModal';
export const CreateUpdatePipingWorkloadModalName = 'CreateUpdatePipingWorkloadModalName';
export const SavingTestPackage = 'SavingTestPackage';
export const RemovingTestPackage = 'RemovingTestPackage';
export const GettingTestPackageList = 'GettingTestPackageList';
export const SavingTrackingIssue = 'SavingTrackingIssue';
export const RemovingTrackingIssue = 'RemovingTrackingIssue';
export const RemovingTrackingIssues = 'RemovingTrackingIssues';
export const RemovingTrackingIssuesByProject = 'RemovingTrackingIssuesByProject';

export const CreateUpdateStructureBaseSetModalName = 'CreateUpdateStructureBaseSetModal';
export const CreateUpdateMultipleStructureBaseSetModalName = 'CreateUpdateMultipleStructureBaseSetModalName';
export const GettingStructureBaseSetList = 'GettingStructureBaseSetList';
export const SavingStructureBaseSet = 'SavingStructureBaseSet';
export const RemovingStructureBaseSet = 'RemovingStructureBaseSet';
export const RemovingStructureBaseSetByProject = 'RemovingStructureBaseSetByProject';
export const RemovingMultipleStructureBaseSet = 'RemovingMultipleStructureBaseSet';
export const ImportingStructureBaseSet = 'ImportingStructureBaseSet';
export const ExportingStructureBaseSet = 'ExportingStructureBaseSet';

export const GettingStructureBaseSetColumn = 'GettingStructureBaseSetColumn';
export const GettingStructureBaseSetColumnAggregateValue = 'GettingStructureBaseSetColumnAggregateValue';
export const SavingMultipleStructureBaseSetColumn = 'SavingMultipleStructureBaseSetColumn';
export enum DisciplineOption {
  Piping = 0,
  Equipment = 1,
  E_AND_I = 2,
  Structure = 3,
  Civil = 4,
}

export const DisciplineOptionType: { [key: string]: number } = {
  PipingTask: 0,
  PipingTestPackage: 0,
  EquipmentTask: 1,
  'E&ITask': 2,
  StructureTask: 3,
  CivilTask: 4,
};
export enum FileStatusConstant {
  repairing = 'repairing',
  uploading = 'uploading',
  success = 'success',
  error = 'error',
}
export interface FileStatus {
  percent: number;
  status: FileStatusConstant;
  file?: FormData;
  fileId: string;
  documentId?: string;
  name?: string;
  error?: string;
}

export interface Organization {
  guid: string;
  invitationStatus: string;
  companyName: string;
  billingAddress: string;
  billingAddress2: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  crmId: string;
  duns: string;
  naics: string;
  companyId: number;
}
