import { Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateUpdateFolderModalName, SavingLabel } from '@/common/define';
import { documentActions, getDocumentPath, getFolderRootId } from '@/store/document';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { getModalVisible, hideModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

export const CreateUpdateFolderModal = () => {
  const { t } = useTranslation('document');
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(getSelectedProject());
  const documentPath = useAppSelector(getDocumentPath());
  const folderRootId = useAppSelector(getFolderRootId());
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateFolderModalName));
  const folderCreating = useAppSelector(getLoading(SavingLabel));

  const lastPath = documentPath[(documentPath?.length || 1) - 1];

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    dispatch(hideModal({ key: CreateUpdateFolderModalName }));
  };
  const handleSaveFolder = (values: any) => {
    if (selectedProject) {
      if (!documentPath.length) {
        dispatch(
          documentActions.createLabelRequest({
            label: {
              ...values,
              type: 'folder',
              parentId: folderRootId,
            },
            projectId: selectedProject.id,
          }),
        );
      } else {
        dispatch(
          documentActions.createLabelRequest({
            label: {
              ...values,
              type: 'folder',
              parentId: lastPath?.id || undefined,
            },
            projectId: selectedProject.id,
          }),
        );
      }
    }
  };

  return (
    <Modal title={t('New folder')} confirmLoading={folderCreating} open={isModalOpen} onCancel={handleCancel} onOk={handleOk}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: 'Untitled',
        }}
        onFinish={handleSaveFolder}
      >
        <Form.Item name={'name'}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
