import { useState, useEffect } from 'react';

import { Button, Row, Space, Typography, Popconfirm, Flex, Progress, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CreateUpdateLinkedDocumentModalName } from '@/common/define';
import { getEnvVars } from '@/environment';
import { SSNameDocumentResponse } from '@/services/IssueService';
import { getActiveMenu, getAuthenticated } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSelectedSSNameDocumentRows, linkedDocumentActions } from '@/store/linkedDocument';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import Utils from '@/utils';

const { apiUrl } = getEnvVars();
const { Text } = Typography;
var count = 0;
var eventSource: EventSource | null = null;
var isInit = true;

export const LinkedDocumentsHeader = () => {
  const { t } = useTranslation('BasedData');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSynchronizing, setSynchronizing] = useState(false);
  const [processPercent, setProcessPercent] = useState(0);
  const [syncedFile, setSyncedFile] = useState(0);
  const [syncedFiles, setSyncedFiles] = useState(0);

  const auth = useAppSelector(getAuthenticated());
  const selectedProject = useAppSelector(getSelectedProject());
  const activeMenu = useAppSelector(getActiveMenu());
  const selectedSSNameDocumentRows = useAppSelector(getSelectedSSNameDocumentRows());

  useEffect(() => {
    return () => {
      // Cleanup SSE connection on component unmount
      eventSource?.close();
      eventSource = null;
    };
  }, []);

  const createLinkedDocument = () => {
    dispatch(showModal({ key: CreateUpdateLinkedDocumentModalName }));
  };

  const GetSynchronizing = () => {
    console.log('GetSynchronizing', eventSource);
    const url = `${apiUrl}/api/Pcvm/sync-progress?projectId=${selectedProject?.id}`;
    console.log('url link', url);
    const source = new EventSource(url);
    count = 0;
    source.onopen = () => {
      console.log('Connection to server opened.');
    };
    source.onmessage = event => {
      let response = JSON.parse(event.data);
      let percent = Number(response.Progress ?? '0');
      if (response.Files) {
        setSyncedFiles(Number(response.Files ?? '0'));
      }
      if (response.SyncedFiles) {
        setSyncedFile(Number(response.SyncedFiles ?? '0'));
      }
      setProcessPercent(percent);
      if (percent >= 0 && percent < 100) {
        isInit = false;
        setSynchronizing(true);
      } else if (percent === 100) {
        reloadData();
        eventSource?.close();
        setTimeout(
          () => {
            setSynchronizing(false);
            setSyncedFiles(0);
            setSyncedFile(0);
          },
          isInit ? 0 : 6000,
        );
      } else {
        eventSource?.close();
        setSynchronizing(false);
      }
    };
    source.onerror = event => {
      count++;
    };
    if (eventSource != null) {
      eventSource.close();
      eventSource = null;
    }
    eventSource = source;
  };

  const handleSyncAllSSNameDocument = () => {
    isInit = false;
    setSynchronizing(true);
    setSyncedFiles(0);
    setSyncedFile(0);
    setProcessPercent(0);

    const url = `${apiUrl}/api/Pcvm/syncDocumentPAMS/${selectedProject?.id}`;
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error('Error starting sync:', error);
        setSynchronizing(false);
      });
    setTimeout(() => {
      GetSynchronizing();
    }, 500);
  };

  const handleCancelSync = () => {
    if (selectedProject) {
      const url = `${apiUrl}/api/Pcvm/stop-progress/${selectedProject.id}`;
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
        .then(response => {
          if (response.ok) {
            eventSource?.close();
            setSynchronizing(false);
            setSyncedFiles(0);
            setSyncedFile(0);
            setProcessPercent(0);
            reloadData();
          } else {
            console.error('Failed to stop sync:', response.statusText);
          }
        })
        .catch(error => {
          console.error('Error stopping sync:', error);
        });
    }
  };

  const handleSyncSSNameDocuments = async (ssNameDocuments: SSNameDocumentResponse[]) => {
    isInit = false;
    setSynchronizing(true);
    setSyncedFiles(0);
    setSyncedFile(0);
    const input = ssNameDocuments.map(document => document.selectionSetName);
    const url = `${apiUrl}/api/Pcvm/syncDocumentPAMsWithSSName/${selectedProject?.id}`;
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(input),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          response.json().then(data => {
            console.log(data);
            Utils.errorNotification(data.Message);
          });
        }
      })
      .catch(error => {
        console.error('Error starting sync:', error);
        setSynchronizing(false);
      });
    setTimeout(() => {
      GetSynchronizing();
    }, 100);
  };

  const reloadData = () => {
    if (selectedProject) {
      dispatch(
        linkedDocumentActions.getSSNameDocumentsRequest({
          params: {},
          projectId: selectedProject.id,
          areaId: -1,
        }),
      );
    }
  };

  const handleOk = () => {
    setSynchronizing(false);
    navigate('/');
  };

  const handleCancel = () => {
    handleCancelSync();
    if (eventSource) {
      eventSource.close();
      eventSource = null;
    }
    setProcessPercent(0);
    setSynchronizing(false);
  };

  return (
    <div>
      <Row style={{ padding: 10, backgroundColor: 'white' }}>
        <Space style={{ flex: 1 }} wrap>
          <Typography.Title style={{ margin: 0, marginRight: 8 }} level={4}>
            {activeMenu?.label}
          </Typography.Title>
          <Button
            type="primary"
            onClick={handleSyncAllSSNameDocument}
            disabled={isSynchronizing}
            style={{ backgroundColor: isSynchronizing ? '#DADADA' : '#009beb' }}
          >
            All Sync
          </Button>
          {!!selectedSSNameDocumentRows?.length && (
            <Button
              type="primary"
              onClick={() => handleSyncSSNameDocuments(selectedSSNameDocumentRows)}
              disabled={isSynchronizing}
              style={{ backgroundColor: isSynchronizing ? '#DADADA' : '#009beb' }}
            >
              Selected Sync
            </Button>
          )}
          {!isInit && isSynchronizing && (
            <Flex
              gap="small"
              align="center"
              style={{
                justifyContent: 'center',
                width: 280,
              }}
            >
              <Popconfirm
                title={t('Do you want to continue synchronizing?')}
                onConfirm={() => {
                  handleCancelSync();
                }}
                onCancel={() => {
                  GetSynchronizing();
                }}
                okText={t('Ok')}
                cancelText={t('Cancel')}
              >
                <Button style={{ background: '#009beb', color: 'white' }} type="default">
                  Cancel
                </Button>
              </Popconfirm>
              <Progress width={100} percent={processPercent} />
            </Flex>
          )}
        </Space>
      </Row>
      <Modal
        title="Synchronization in Progress"
        visible={isSynchronizing}
        closable={false}
        maskClosable={false}
        onCancel={handleCancel}
        onOk={handleOk}
        okText="OK"
      >
        <p>Documents are being synchronized. Please wait...</p>
        <Progress width={100} percent={processPercent} />
      </Modal>
    </div>
  );
};
