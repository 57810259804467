import { Key } from 'react';

import { notification } from 'antd';
import i18next from 'i18next';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';

export default class Utils {
  static throttleTimer: boolean;
  static throttle = (callback: () => void, time?: number) => {
    if (Utils.throttleTimer) {
      return;
    }

    Utils.throttleTimer = true;

    setTimeout(() => {
      callback();
      Utils.throttleTimer = false;
    }, time || 500);
  };
  static deepClone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  static getPersistAppState() {
    const persistState = localStorage.getItem('persist:root');
    const rootState = persistState ? JSON.parse(persistState) : {};
    /* prettier-ignore */
    const persistAppState: any = rootState['app'] ? JSON.parse(rootState['app']) : {};
    return persistAppState;
  }

  static isTokenValid(token: string) {
    if (!token || typeof token !== 'string') {
      return false;
    }

    const decoded = jwtDecode(token);

    if (!decoded.exp) {
      return false;
    }

    const now = new Date().valueOf();

    return now < decoded.exp * 1000;
  }

  static convertISODateToLocalTime(isoDateString: string) {
    const date = new Date(isoDateString);
    const timestampWithOffset = date.getTime();
    const offset = date.getTimezoneOffset() * 60 * 1000;
    const timestampWithoutOffset = timestampWithOffset - offset;
    const dateWithoutOffset = new Date(timestampWithoutOffset);
    return dateWithoutOffset;
  }

  static createUUID = () => uuidv4();

  static stringToColour = (str?: string) => {
    if (!str) {
      str = uuidv4();
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = (hash & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - colour.length) + colour;
  };

  static errorHandling(error: any) {
    console.log(JSON.stringify(error));
    if (error.errorCode && error.msg) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.msg),
        placement: 'top',
        duration: 2.5,
      });
      return;
    }
    if (error.status === 401) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t('Token expired'),
        placement: 'top',
        duration: 2.5,
      });
      return;
    }
    if (error.status === 404) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.message || 'Not Found'),
        placement: 'top',
        duration: 2.5,
      });
      return;
    }
    if (error.status === 403) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t('Forbidden'),
        placement: 'top',
        duration: 2.5,
      });
      return;
    }
    if (typeof error.response === 'string') {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.response),
        placement: 'top',
        duration: 2.5,
      });
      return;
    }
    if (error?.response?.error_description) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.response.error_description),
        placement: 'top',
        duration: 2.5,
      });
      return;
    }
    if (error.response?.error) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.response?.error),
        placement: 'top',
        duration: 2.5,
      });
      return;
    }
    if (error.response?.Message) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.response?.Message),
        placement: 'top',
        duration: 2.5,
      });
      return;
    }
    // TODO:
    notification.error({
      message: i18next.t('notification'),
      description: i18next.t('An error occurred while processing your request'),
      placement: 'top',
      duration: 2.5,
    });
  }

  static successNotification(message?: string) {
    notification.success({
      message: i18next.t('notification'),
      description: i18next.t(message || 'Saved successfully'),
      placement: 'top',
      duration: 2.5,
    });
  }
  static errorNotification(message?: string) {
    notification.error({
      message: i18next.t('notification'),
      description: i18next.t(message || 'Saved error'),
      placement: 'top',
      duration: 3,
    });
  }
  static developingNotification() {
    notification.info({
      message: i18next.t('notification'),
      description: i18next.t('Under development'),
      placement: 'top',
      duration: 2.5,
    });
  }
  static readableFileSize(attachmentSize: number) {
    const DEFAULT_SIZE = 0;
    const fileSize = attachmentSize ?? DEFAULT_SIZE;

    if (!fileSize) {
      return `${DEFAULT_SIZE} KB`;
    }

    const sizeInKb = fileSize / 1024;

    if (sizeInKb > 1024) {
      return `${(sizeInKb / 1024).toFixed(2)} MB`;
    } else {
      return `${sizeInKb.toFixed(2)} KB`;
    }
  }
  static toCapitalize(word: string) {
    const firstLetter = word.charAt(0);

    const firstLetterCap = firstLetter.toUpperCase();

    const remainingLetters = word.slice(1);

    const capitalizedWord = firstLetterCap + remainingLetters;
    return capitalizedWord;
  }
  static convertAntFilterToParams(filters: Record<string, (Key | boolean)[] | null>) {
    let params: any = {};
    for (const key of Object.keys(filters)) {
      if (filters[key]?.length) {
        params[key] = filters[key]?.[0];
      } else {
        params[key] = filters[key] || undefined;
      }
    }
    console.log({
      params,
      filters,
    });

    return params;
  }
  static isObjectEmpty(obj: any) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop) && !!obj[prop]) {
        return false;
      }
    }

    return true;
  }
  static divideArray(array: any[], K: number) {
    let ans: any[] = [];
    let temp: any[] = [];
    array.forEach((item, i) => {
      temp.push(item);
      if ((i + 1) % K === 0) {
        ans.push(temp);
        temp = [];
      }
    });

    // If last group doesn't have enough
    // elements then add 0 to it
    if (temp.length !== 0) {
      let a = temp.length;
      while (a !== K) {
        temp.push(0);
        a++;
      }
      ans.push(temp);
    }
    return ans;
  }
  static getPrameterToken(search: string) {
    const query = new URLSearchParams(search);
    var refresh_token = query.get('refresh_token');
    var orgId = query.get('orgId');
    var projectId = query.get('projectId');

    return {
      refresh_token,
      orgId,
      projectId,
    };
  }
}
