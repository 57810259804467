import { saveAs } from 'file-saver';
import { catchError, concat, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

import { linkedDocumentActions } from './linkedDocumentSlice';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  CreateUpdateLinkedDocumentModalName,
  DownloadingLinkedDocument,
  GetFoldersRequest,
  GettingLinkedDocumentList,
  GettingSSNameDocumentList,
  RemovingLinkedDocument,
  SavingLinkedDocument,
  defaultPagingParams,
  importLinkedDocument,
  synchronizingAllSSNameDocument,
  synchronizingSSNameDocuments,
} from '@/common/define';
import { DocumentService } from '@/services/DocumentService';
import { IssueService } from '@/services/IssueService';
import { ProjectService } from '@/services/ProjectService';
import Utils from '@/utils';

const getSSNameDocumentsRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(linkedDocumentActions.getSSNameDocumentsRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, areaId, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.linkedDocument.queryParams, ...params, areaId };
      return concat(
        [startLoading({ key: GettingSSNameDocumentList })],
        IssueService.Get.getSSNameDocuments(projectId, areaId, { search }).pipe(
          mergeMap(ssNameDocuments => {
            return [
              linkedDocumentActions.setQueryParams(search),
              linkedDocumentActions.setSSNameDocuments(ssNameDocuments),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [linkedDocumentActions.setSSNameDocuments(undefined)];
          }),
        ),
        [stopLoading({ key: GettingSSNameDocumentList })],
      );
    }),
  );
};

const getLinkedDocumentsRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(linkedDocumentActions.getLinkedDocumentsRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.linkedDocument.queryParams, ...params };
      return concat(
        [startLoading({ key: GettingLinkedDocumentList })],
        IssueService.Get.getLinkedDocuments(projectId, { search }).pipe(
          mergeMap(ssNameDocuments => {
            return [
              linkedDocumentActions.setLinkedDocumentQueryParams(search),
              linkedDocumentActions.setLinkedDocuments(ssNameDocuments),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [linkedDocumentActions.setLinkedDocuments(undefined)];
          }),
        ),
        [stopLoading({ key: GettingLinkedDocumentList })],
      );
    }),
  );
};

const getfoldersRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(linkedDocumentActions.getFoldersRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.area.queryParams, ...params };
      console.log(search);
      return concat(
        [startLoading({ key: GetFoldersRequest })],
        IssueService.Get.getFoldersRequest(projectId, { search }).pipe(
          mergeMap(res => {
            return [linkedDocumentActions.setFolders(res)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [linkedDocumentActions.setFolders(undefined)];
          }),
        ),
        [stopLoading({ key: GetFoldersRequest })],
      );
    }),
  );
};

const downloadFile$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(linkedDocumentActions.downloadFile.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { linkedDocument, search } = action.payload;
      return concat(
        [startLoading({ key: DownloadingLinkedDocument })],
        DocumentService.Get.downloadFile(linkedDocument.id, { search }).pipe(
          mergeMap((linkedDocumentBlob: any) => {
            if (linkedDocumentBlob) {
              saveAs(linkedDocumentBlob, linkedDocument.name);
            }
            Utils.successNotification();
            return [];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: DownloadingLinkedDocument })],
      );
    }),
  );
};

const removeSSNameDocumentRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(linkedDocumentActions.removeSSNameDocumentRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { linkedDocumentId, projectId } = action.payload;
      const search = { ...defaultPagingParams, ...state.linkedDocument.queryParams, page: 1 };
      return concat(
        [startLoading({ key: RemovingLinkedDocument })],
        DocumentService.Delete.deleteDocument(linkedDocumentId).pipe(
          switchMap(() => {
            return DocumentService.Get.getDocumentsByProjectId(projectId, { search }).pipe(
              mergeMap(linkedDocumentsResult => {
                Utils.successNotification('Removed successfully');
                return [hideModal({ key: CreateUpdateLinkedDocumentModalName })];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [linkedDocumentActions.setSSNameDocuments(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: RemovingLinkedDocument })],
      );
    }),
  );
};

const importLinkedDocument$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(linkedDocumentActions.importLinkedDocument.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, areaId, file } = action.payload;
      const data = new FormData();
      data.append('file', file);
      const search = state.linkedDocument.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: importLinkedDocument })],
        IssueService.Post.importSSLinkedDocuments(projectId, data, {}).pipe(
          switchMap(() => {
            return IssueService.Get.getSSNameDocuments(projectId, areaId, { search: search }).pipe(
              map(issues => {
                Utils.successNotification('Imported successfully.');
                return linkedDocumentActions.setSSNameDocuments(issues);
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [linkedDocumentActions.setSSNameDocuments(undefined)];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: importLinkedDocument })],
      );
    }),
  );
};

const addDocumentForSSName$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(linkedDocumentActions.addDocumentForSSName.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { ssNameDocument, areaId, input, projectId } = action.payload;
      const search = state.linkedDocument.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: SavingLinkedDocument })],
        IssueService.Put.addDocumentForSSName(input, {
          search: {
            projectId,
            selectionSetName: ssNameDocument.selectionSetName,
          },
        }).pipe(
          switchMap(re => {
            return IssueService.Get.getSSNameDocuments(projectId, areaId, { search: search }).pipe(
              mergeMap(issues => {
                Utils.successNotification('Saved successfully.');
                return [
                  linkedDocumentActions.setSSNameDocuments(issues),
                  hideModal({ key: CreateUpdateLinkedDocumentModalName }),
                ];
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [linkedDocumentActions.setSSNameDocuments(undefined)];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: SavingLinkedDocument })],
      );
    }),
  );
};

const syncAllSSNameDocument$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(linkedDocumentActions.syncAllSSNameDocument.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { selectedProject } = state.project;
      if (!selectedProject) return [];
      const { areaId = -1 } = state.linkedDocument.queryParams;
      const search = state.linkedDocument.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: synchronizingAllSSNameDocument })],
        ProjectService.Post.syncAllSSNameDocument(selectedProject?.id).pipe(
          switchMap(() => {
            return IssueService.Get.getSSNameDocuments(selectedProject.id, areaId, { search: search }).pipe(
              map(documents => {
                Utils.successNotification('Sync successfully.');
                return linkedDocumentActions.setSSNameDocuments(documents);
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: synchronizingAllSSNameDocument })],
      );
    }),
  );
};

const syncSSNameDocuments$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(linkedDocumentActions.syncSSNameDocuments.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { selectedProject } = state.project;
      if (!selectedProject) return [];
      const { body } = action.payload;
      const { areaId = -1 } = state.linkedDocument.queryParams;
      const search = state.linkedDocument.queryParams || defaultPagingParams;
      return concat(
        [startLoading({ key: synchronizingSSNameDocuments })],
        ProjectService.Post.syncSSNameDocuments(selectedProject?.id, body).pipe(
          switchMap(() => {
            return IssueService.Get.getSSNameDocuments(selectedProject.id, areaId, { search: search }).pipe(
              map(documents => {
                Utils.successNotification('Sync successfully.');
                return linkedDocumentActions.setSSNameDocuments(documents);
              }),
              catchError(error => {
                Utils.errorHandling(error);
                return [];
              }),
            );
          }),
          catchError((error: AjaxError) => {
            Utils.errorHandling({
              errorCode: error?.response?.StatusCode || '',
              msg: error?.response?.Message || '',
            });
            return [];
          }),
        ),
        [stopLoading({ key: synchronizingSSNameDocuments })],
      );
    }),
  );
};
export const linkedDocumentEpics = [
  getSSNameDocumentsRequest$,
  importLinkedDocument$,
  downloadFile$,
  addDocumentForSSName$,
  getfoldersRequest$,
  syncSSNameDocuments$,
  syncAllSSNameDocument$,
];
