import React from 'react';

import { LoadingOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Layout, Spin, Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './LayoutHeader.module.less';
import { UserLogin } from './UserLogin';
import { colors } from '@/common/colors';
import { Installation, LOGO } from '@/common/define';
import { useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import Utils from '@/utils';

const { Header } = Layout;

export const LayoutHeader = () => {
  const loading = useAppSelector(getLoading());

  const navigate = useNavigate();
  const location = useLocation();
  const { refresh_token } = Utils.getPrameterToken(location.search);
  const handleDownload = () => {
    // Replace 'installation.exe' with the actual file name and path
    const fileUrl = Installation;
    const newWindow = window.open(fileUrl);
    // Clean up the URL object after download
    setTimeout(() => {
      newWindow?.close();
    }, 500);
  };
  return (
    <Header className={styles.headerContainer} style={{ borderBottom: `1px solid ${colors.header.border}` }}>
      <div className={styles.headerLeft}>
        <div
          className={styles.logoContainer}
          role="button"
          onClick={() => navigate('/')}
          onKeyDown={() => navigate('/')}
          tabIndex={0}
        >
          <img src={LOGO} alt="Logo" className={styles.logo} />
        </div>
      </div>
      <div className={styles.headerRight}>
        <Tooltip title="Download Installation" placement="left">
          <Button
            style={{ backgroundColor: 'transparent' }}
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleDownload}
          ></Button>
        </Tooltip>

        {!refresh_token && <UserLogin />}
        <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} />} spinning={loading} style={{ marginLeft: 10 }} />
      </div>
    </Header>
  );
};
