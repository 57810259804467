import React, { useEffect, useState } from 'react';

import { Modal, Form, Input, DatePicker, Select, Row, message, Button, Space } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  CreateUpdatePipingWorkloadModalName,
  CreateUpdateTrackingIssueModalName,
  SavingTrackingIssue,
  formatDateDisplay,
  invalidDateStrings,
} from '@/common/define';
import { DraggableModal } from '@/components/Modal/DragableModal';
import { AttributeResponse, IssueResponse, IssueTarget } from '@/services/IssueService';
import { getAreas } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getAttributes,
  getIssueQueryParams,
  getSelectedIssue,
  getTargets,
  getTrackerId,
  issueActions,
} from '@/store/issue';
import { getLoading } from '@/store/loading';
import { getModalVisible, hideModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { getWorkPackages } from '@/store/workPackage';

export const CreateUpdatePipingWorkload = () => {
  const { t } = useTranslation('issue');
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdatePipingWorkloadModalName));

  const selectedIssue: IssueResponse | null | undefined = useAppSelector(getSelectedIssue());
  const [title, setTitle] = useState(selectedIssue ? t('edit') : t('new'));
  const selectedProject = useAppSelector(getSelectedProject());
  const trackerId = useAppSelector(getTrackerId());
  const isSaving = useAppSelector(getLoading(SavingTrackingIssue));

  const [form] = Form.useForm();

  const attributes = useAppSelector(getAttributes());
  const targets = useAppSelector(getTargets());
  const areas = useAppSelector(getAreas());
  const workPackages = useAppSelector(getWorkPackages());
  const queryParams = useAppSelector(getIssueQueryParams());
  const [additionAttributes, setAdditionAttributes] = useState<AttributeResponse[]>([]);
  const [initialValues, setInitialValues] = useState<any>({});
  const areaOptions = (areas?.results || []).map(area => ({
    label: area.name,
    value: area.id,
  }));

  const workPackageOptions = (workPackages?.results || []).map(workPackage => ({
    label: workPackage.name,
    value: workPackage.id,
  }));

  useEffect(() => {
    const attrList = attributes?.results || [];
    setAdditionAttributes(attrList);
  }, [attributes]);

  useEffect(() => {
    setTitle(selectedIssue ? t('edit') : t('new'));

    if (selectedIssue) {
      var initData: any = {
        ...selectedIssue,
        plannedStartDate:
          selectedIssue?.plannedStartDate && !invalidDateStrings.includes(selectedIssue?.plannedStartDate)
            ? dayjs(selectedIssue?.plannedStartDate)
            : null,
        plannedEndDate:
          selectedIssue?.plannedEndDate && !invalidDateStrings.includes(selectedIssue?.plannedEndDate)
            ? dayjs(selectedIssue?.plannedEndDate)
            : null,
        actualStartDate:
          selectedIssue?.actualStartDate && !invalidDateStrings.includes(selectedIssue?.actualStartDate)
            ? dayjs(selectedIssue?.actualStartDate)
            : null,
        actualEndDate:
          selectedIssue?.actualEndDate && !invalidDateStrings.includes(selectedIssue?.actualEndDate)
            ? dayjs(selectedIssue?.actualEndDate)
            : null,
      };
      for (let att of selectedIssue?.attributes || []) {
        initData[att.code] = att.value;
      }
      for (let taget of selectedIssue?.issueTargets || []) {
        initData[taget.targetDim.code] = taget;
      }
      console.log(initData);
      form.setFieldsValue(initData);
      // setInitialValues(initData);
    }
  }, [selectedIssue]);
  const handleSaveIssue = (values: any) => {
    if (selectedProject) {
      const inputData = {
        ...values,
        projectId: selectedProject.id,
        trackerId,
        ...{
          issueAttributes: additionAttributes.map((attr: AttributeResponse) => ({
            attributeId: attr.id,
            value: values[attr.code],
          })),
        },
        issueTargetUpdates: (targets?.results || []).map(target => ({
          targetId: target.id,
          planValue: values[target.code].planValue,
          actualValue: values[target.code].actualValue,
        })),
      };

      if (selectedIssue) {
        const editInputData = {
          ...inputData,
        };
        dispatch(
          issueActions.updateIssueRequest({ issueId: selectedIssue.id, issue: { ...selectedIssue, ...editInputData } }),
        );
      } else {
        dispatch(issueActions.createIssueRequest({ issue: inputData }));
      }

      dispatch(hideModal({ key: CreateUpdatePipingWorkloadModalName }));
    }
  };

  const handleCancel = () => {
    dispatch(issueActions.setSelectedIssue(undefined));
    dispatch(hideModal({ key: CreateUpdatePipingWorkloadModalName }));
  };

  const handleOk = () => form.submit();

  // const haveAdditional = additionAttributes.length > 0;
  const confirmRemoveIssue = () => {
    if (selectedIssue) {
      Modal.confirm({
        title: t('remove'),
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: t('confirmRemove', {
                name: `<strong>"${selectedIssue.subject}"</strong>`,
              }),
            }}
          />
        ),
        closable: true,
        onOk: close => {
          dispatch(
            issueActions.deleteIssueRequest({
              issueId: selectedIssue.id,
              projectId: selectedProject?.id,
              trackerId: trackerId,
            }),
          );
          dispatch(issueActions.setSelectedIssue(undefined));
          close();
        },
      });
    }
  };
  return (
    <DraggableModal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('save')}
      confirmLoading={isSaving}
      footer={(_, { OkBtn, CancelBtn }) =>
        selectedIssue ? (
          <Row style={{ margin: 0 }} align="stretch">
            <Space style={{ flex: 1 }}>
              <Button
                key="remove"
                type="primary"
                danger
                onClick={() => {
                  confirmRemoveIssue();
                  dispatch(hideModal({ key: CreateUpdatePipingWorkloadModalName }));
                }}
              >
                {t('remove')}
              </Button>
            </Space>
            <Space>
              <CancelBtn />
              <OkBtn />
            </Space>
          </Row>
        ) : (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )
      }
    >
      <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleSaveIssue}>
        <Form.Item label={t('Name')} name="subject" rules={[{ required: true, message: t('Please input name!') }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t('Description')} name="description">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          {additionAttributes.map(input => (
            <Form.Item
              label={t(input.name)}
              name={input.code}
              rules={[{ required: true, message: t('requiredField') }]}
              style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 5 }}
            >
              <Input />
            </Form.Item>
          ))}
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={initialValues?.PlannedWeldingDI?.targetDim?.name || t('Planned Welding D/I')}
            name={['PlannedWeldingDI', 'planValue']}
            rules={[{ required: true, message: t('requiredField') }]}
            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 5 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={initialValues?.PlannedWeldingDI?.targetDim?.actualValueDisplayName || t('Actual Welding D/I')}
            name={['PlannedWeldingDI', 'actualValue']}
            rules={[{ required: true, message: t('requiredField') }]}
            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 5 }}
          >
            <Input />
          </Form.Item>
        </Form.Item>
      </Form>
    </DraggableModal>
  );
};
