import { useCallback, useEffect, useState } from 'react';

import { EditOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  notification,
  PaginationProps,
  Space,
  Spin,
  Table,
  TableProps,
  Tooltip,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';

import {
  CreateUpdateLinkedDocumentModalName,
  DownloadingLinkedDocument,
  GettingLinkedDocumentList,
  GettingSSNameDocumentList,
  RemovingLinkedDocument,
  UploadingLinkedDocument,
  defaultPagingParams,
} from '@/common/define';
import { useWindowSize } from '@/hooks';
import { LinkedDocument, SSNameDocumentResponse } from '@/services/IssueService';
import { getAuthenticated } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getSelectedSSNameDocumentRows,
  getSSNameDocumentQueryParams,
  getSSNameDocuments,
  linkedDocumentActions,
} from '@/store/linkedDocument';
import { getLoading } from '@/store/loading';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';

const rowKey = 'selectionSetName';
export const LinkedDocumentsTable = () => {
  const [notiApi, contextHolder] = notification.useNotification();
  const { t } = useTranslation(['linkedDocument', 'common']);
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();

  const params = useAppSelector(getSSNameDocumentQueryParams());
  const auth = useAppSelector(getAuthenticated());
  const ssNameDocuments = useAppSelector(getSSNameDocuments());
  const selectedProject = useAppSelector(getSelectedProject());
  const selectedSSNameDocumentRows = useAppSelector(getSelectedSSNameDocumentRows());
  const isLoading = useAppSelector(getLoading(GettingSSNameDocumentList));
  const isRemoving = useAppSelector(getLoading(RemovingLinkedDocument));
  const isUploadingLinkedDocument = useAppSelector(getLoading(UploadingLinkedDocument));
  const isDownloadingLinkedDocument = useAppSelector(getLoading(DownloadingLinkedDocument));

  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    var tempData = ssNameDocuments?.results || [];
    // var convertList: any = {};
    // if (tempData) {
    //   for (let i = 0; i < tempData.length; i++) {
    //     var nameSets = tempData[i].selectionSetNames.split(',');
    //     var temData = tempData[i];
    //     for (let j = 0; j < nameSets.length; j++) {
    //       var doc: any[] = convertList[nameSets[j]]?.documents ?? [];
    //       if (doc.find(x => x.id === temData.id) === undefined) {
    //         doc.push(tempData[i]);
    //       }
    //       convertList[nameSets[j]] = {
    //         id: tempData[i].id + nameSets[j],
    //         key: nameSets[j],
    //         name: nameSets[j],
    //         documents: doc,
    //       };
    //     }
    //   }
    // }
    // var temp = [];
    // for (var items in convertList) {
    //   temp.push(convertList[items]);
    // }

    // setData(
    //   temp.filter(
    //     x => x.name.includes(params.search) || !params.search || (params.search && params.search.length === 0),
    //   ),
    // );

    setData(tempData);
    dispatch(linkedDocumentActions.setSelectedSSNameDocumentRows([]));
  }, [ssNameDocuments]);

  useEffect(() => {
    if (isDownloadingLinkedDocument) {
      notiApi.open({
        message: t('Uploading item'),
        description: (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Typography.Text>{t('Starting upload')}</Typography.Text>
            <Spin indicator={<LoadingOutlined />} />
          </div>
        ),
        duration: 0,
        placement: 'topRight',
      });
    } else {
      notiApi.destroy();
    }
  }, [isDownloadingLinkedDocument]);

  const getMoreActions = useCallback((record: SSNameDocumentResponse) => {
    const moreActions = [
      {
        key: 'edit',
        label: t('Edit'),
        icon: <EditOutlined style={{ color: '#1890ff' }} />,
        onClick: () => editSSNameDocument(record),
      },
      // {
      //   key: 'remove',
      //   label: t('remove'),
      //   icon: <DeleteOutlined style={{ color: '#ff4d4f' }} />,
      //   onClick: () => confirmRemoveSSNameDocument(record),
      //   loading: false,
      // },
    ];

    return moreActions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editSSNameDocument = (ssNameDocument: SSNameDocumentResponse) => {
    dispatch(linkedDocumentActions.setSelectedSSNameDocuments(ssNameDocument));
    dispatch(showModal({ key: CreateUpdateLinkedDocumentModalName }));
  };

  const downloadLinkedDocument = (linkedDocument: LinkedDocument) => {
    dispatch(linkedDocumentActions.downloadFile({ linkedDocument, search: { companyId: auth?.companyId } }));
  };

  const confirmRemoveSSNameDocument = (ssNameDocument: SSNameDocumentResponse) => {
    Modal.confirm({
      title: t('Notification'),
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('confirmRemove', {
              name: `<strong>"${ssNameDocument.name}"</strong>`,
            }),
          }}
        />
      ),
      closable: true,
      onOk: close => {
        handleRemoveSSNameDocument(ssNameDocument.id);
        close();
      },
    });
  };

  const handleRemoveSSNameDocument = (ssNameDocumentId: string) => {
    if (selectedProject) {
      dispatch(
        linkedDocumentActions.removeSSNameDocumentRequest({
          linkedDocumentId: ssNameDocumentId,
          projectId: selectedProject.id,
        }),
      );
    }
  };

  const handleTableChange: TableProps<SSNameDocumentResponse>['onChange'] = (pagination, filters, sorter) => {
    if (selectedProject) {
      const { current, pageSize } = pagination;
      const search = { ...params, page: current, pageSize };
      dispatch(
        linkedDocumentActions.getSSNameDocumentsRequest({
          params: search,
          projectId: selectedProject?.id,
          areaId: params?.areaId || -1,
        }),
      );
    }
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) =>
    t('pagingTotal', { range1: range[0], range2: range[1], total, ns: 'common' });

  const onSelectChange = (newSelectedRowKeys: React.Key[], newSelectedRows: SSNameDocumentResponse[]) => {
    dispatch(linkedDocumentActions.setSelectedSSNameDocumentRows(newSelectedRows));
  };

  // eslint-disable-next-line
  const rowSelection = {
    selectedRowKeys: (selectedSSNameDocumentRows || []).map(row => row[rowKey]),
    onChange: onSelectChange,
    columnWidth: 50,
  };

  const columns: TableProps<any>['columns'] = [
    // {
    //   dataIndex: 'type',
    //   key: 'type',
    //   width: 40,
    //   align: 'center',
    //   render: (value, record) => {
    //     return value !== 'folder' ? (
    //       <></>
    //     ) : (
    //       <FolderOutlined style={{ marginRight: 10, fontSize: 20, cursor: 'pointer' }} />
    //     );
    //   },
    // },
    {
      title: t('Selection set Name'),
      dataIndex: 'selectionSetName',
      key: 'selectionSetName',
      width: 400,
    },
    {
      title: t('Documents'),
      dataIndex: 'documents',
      key: 'documents',
      render: (value, record) => {
        if (record?.documents?.length > 0) {
          return (
            <div>
              {record?.documents?.map((item: any) => (
                <Button
                  style={{ marginRight: 5 }}
                  onClick={() => {
                    downloadLinkedDocument(item);
                  }}
                >
                  {item.name}
                </Button>
              ))}
            </div>
          );
        } else {
          return <div></div>;
        }
      },
    },
    {
      fixed: 'right',
      align: 'center',
      width: 100,
      key: 'action',
      render: (_, record) => {
        return (
          <Space>
            {getMoreActions(record).map(action => (
              <Tooltip title={action.label} key={action.key}>
                <Button onClick={action.onClick} icon={action.icon} type="text" />
              </Tooltip>
            ))}
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={isUploadingLinkedDocument}>
      <div style={{ padding: 10 }}>
        {contextHolder}
        <Table
          rowKey={rowKey}
          // virtual
          dataSource={data}
          columns={columns}
          style={{ width: '100%' }}
          size="small"
          scroll={{ x: 800, y: windowSize[1] - 330 }}
          pagination={{
            current: ssNameDocuments?.page || defaultPagingParams.page,
            pageSize: ssNameDocuments?.pageSize || defaultPagingParams.pageSize,
            total: ssNameDocuments?.queryCount || 0,
            responsive: true,
            showTotal,
          }}
          loading={isLoading || isRemoving}
          onChange={handleTableChange}
          rowSelection={rowSelection}
          expandable={{
            showExpandColumn: false,
          }}
        />
      </div>
    </Spin>
  );
};
