import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ViewState, defaultPagingParams } from '@/common/define';
import {
  AttributePagingResponse,
  IssuePagingResponse,
  IssueResponse,
  ProjectSyncAudit,
  TrackerResponse,
} from '@/services/IssueService';
import { TargetDimPagingResponse } from '@/services/TargetDimService';

interface BiddingState {
  issues?: IssuePagingResponse;
  selectedIssue?: IssueResponse;
  attributes?: AttributePagingResponse;
  targets?: TargetDimPagingResponse;
  tracker?: TrackerResponse;
  view: ViewState;
  trackerId?: number;
  queryParams: any;
  newColumns: any;
  ProjectSyncAudit: ProjectSyncAudit | null;
  syncPipingTaskStatus: { status: number; message: string; syncStatus: number } | null;
  syncTestPackageTaskStatus: { status: number; message: string; syncStatus: number } | null;
  syncRemainPipingTaskStatus: { status: number; message: string; syncStatus: number } | null;
}

const initialState: BiddingState = {
  view: 'List',
  queryParams: defaultPagingParams,
  newColumns: [],
  ProjectSyncAudit: null,
  syncPipingTaskStatus: null,
  syncTestPackageTaskStatus: null,
  syncRemainPipingTaskStatus: null,
};

const issueSlice = createSlice({
  name: 'bidding',
  initialState,
  reducers: {
    setIssues: (state, action) => {
      state.issues = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
    },
    setTrackerId: (state, action) => {
      state.trackerId = action.payload;
    },
    setAttribute: (state, action) => {
      state.attributes = action.payload;
    },
    setTargets: (state, action) => {
      state.targets = action.payload;
    },
    setTracker: (state, action) => {
      state.tracker = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setSelectedIssue: (state, action) => {
      state.selectedIssue = action.payload;
    },
    setNewColumns: (state, action) => {
      state.newColumns = action.payload;
    },
    getIssuesRequest: (state, action) => {},
    getIssuesByTrackerRequest: (state, action) => {},
    getAttributesByTrackerRequest: (state, action) => {},
    getTargetsByTrackerRequest: (state, action) => {},
    getTrackerRequest: (state, action) => {},
    createIssueRequest: (state, action) => {},
    updateIssueRequest: (state, action) => {},
    importFileRequest: (state, action) => {},
    exportFileRequest: (state, action) => {},
    deleteIssueRequest: (state, action) => {},
    deleteIssuesRequest: (state, action) => {},
    validatePipingRequest: (state, action: PayloadAction<{ projectId: number; trackerId?: number }>) => {},
    validateTeskPackageTaskPAMs: (state, action: PayloadAction<{ projectId: number; trackerId?: number }>) => {},
    validateRemainPipingTaskPAMs: (state, action: PayloadAction<{ projectId: number; trackerId?: number }>) => {},
    asyncPipingTaskRequest: (
      state,
      action: PayloadAction<{ projectId: number; trackerId?: number; body: any; errorData: any }>,
    ) => {},
    asyncRemainPipingRequest: (
      state,
      action: PayloadAction<{ projectId: number; trackerId?: number; body: any; errorData: any }>,
    ) => {},
    asyncTestPackageRequest: (
      state,
      action: PayloadAction<{ projectId: number; trackerId?: number; body: any; errorData: any }>,
    ) => {},

    syncPAMSRequest: (
      state,
      action: PayloadAction<{
        projectId: number;
        trackerId?: number;
        PipingTaskDataSync: any;
        RemainPipingDataSync: any;
        TestPackageDataSync: any;
        PipingTaskError: any;
        RemainPipingError: any;
        TestPackageError: any;
      }>,
    ) => {},
    deleteIssuesByProjectRequest: (
      state,
      action: PayloadAction<{ projectId: number; areaId: number; workPackageId: number; trackerId: number }>,
    ) => {},

    createProjectSyncAuditRequest: (state, action: PayloadAction<{ input: ProjectSyncAudit }>) => {},

    setProjectSyncAudit: (state, action: PayloadAction<ProjectSyncAudit>) => {
      state.ProjectSyncAudit = action.payload;
    },

    updateProjectSyncAuditRequest: (
      state,
      action: PayloadAction<{ projectId: number; auditData: ProjectSyncAudit }>,
    ) => {},

    setSyncPipingTaskStatus: (
      state,
      action: PayloadAction<{ status: number; message: string; syncStatus: number } | null>,
    ) => {
      state.syncPipingTaskStatus = action.payload;
    },
    setSyncTestPackageTaskStatus: (
      state,
      action: PayloadAction<{ status: number; message: string; syncStatus: number } | null>,
    ) => {
      state.syncTestPackageTaskStatus = action.payload;
    },
    setSyncRemainPipingTaskStatus: (
      state,
      action: PayloadAction<{ status: number; message: string; syncStatus: number } | null>,
    ) => {
      state.syncRemainPipingTaskStatus = action.payload;
    },
  },
});

export const issueActions = issueSlice.actions;
export const issueReducer = issueSlice.reducer;
