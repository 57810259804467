import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { AreasPagingResponse } from '@/services/AreaService';
import {
  LinkedDocument,
  LinkedDocumentPagingResponse,
  SSNameDocumentPagingResponse,
  SSNameDocumentResponse,
} from '@/services/IssueService';

interface LinkedDocumentState {
  linkedDocuments?: LinkedDocumentPagingResponse;
  ssNameDocuments?: SSNameDocumentPagingResponse;
  selectedSSNameDocument?: SSNameDocumentResponse;
  selectedLinkedDocuments?: LinkedDocument[];
  areas?: AreasPagingResponse;
  queryParams: any;
  linkedDocumentQueryParams: any;
  folders?: string[];
  selectedSSNameDocumentRows: SSNameDocumentResponse[];
}

const initialState: LinkedDocumentState = {
  queryParams: defaultPagingParams,
  linkedDocumentQueryParams: defaultPagingParams,
  selectedSSNameDocumentRows: [],
};

const linkedDocumentSlice = createSlice({
  name: 'linkedDocument',
  initialState,
  reducers: {
    setSelectedLinkedDocuments: (state, action) => {
      state.selectedLinkedDocuments = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    getSSNameDocumentsRequest: (
      state,
      action: PayloadAction<{ projectId: number; areaId: number; params?: any }>,
    ) => {},
    setLinkedDocumentQueryParams: (state, action) => {
      state.linkedDocumentQueryParams = action.payload;
    },
    setLinkedDocuments: (state, action: PayloadAction<LinkedDocumentPagingResponse | undefined>) => {
      state.linkedDocuments = action.payload;
    },
    getLinkedDocumentsRequest: (state, action) => {},
    getAreasRequest: (state, action) => {},
    removeSSNameDocumentRequest: (state, action: PayloadAction<{ linkedDocumentId: string; projectId: number }>) => {},
    setSSNameDocuments: (state, action: PayloadAction<SSNameDocumentPagingResponse | undefined>) => {
      state.ssNameDocuments = action.payload;
    },
    setSelectedSSNameDocuments: (state, action: PayloadAction<SSNameDocumentResponse | undefined>) => {
      state.selectedSSNameDocument = action.payload;
      if (action.payload?.documents?.length) {
        state.selectedLinkedDocuments = action.payload.documents || [];
      } else {
        state.selectedLinkedDocuments = [];
      }
    },
    setAreas: (state, action) => {
      state.areas = action.payload;
    },
    downloadFile: (state, action) => {},
    importLinkedDocument: (state, action) => {},

    addDocumentForSSName: (
      state,
      action: PayloadAction<{
        projectId: number;
        areaId: number;
        input: LinkedDocument[];
        ssNameDocument: SSNameDocumentResponse;
      }>,
    ) => {},

    getFoldersRequest: (state, action: PayloadAction<{ projectId: number; params?: any }>) => {},
    setFolders: (state, action) => {
      console.log(action.payload);
      state.folders = action.payload;
    },
    setSelectedSSNameDocumentRows: (state, action) => {
      state.selectedSSNameDocumentRows = action.payload;
    },
    syncAllSSNameDocument: (state, action) => {},
    syncSSNameDocuments: (state, action: PayloadAction<{ body: string[] }>) => {},
  },
});

export const linkedDocumentActions = linkedDocumentSlice.actions;
export const linkedDocumentReducer = linkedDocumentSlice.reducer;
