import { useState } from 'react';

import { Spin, Table, TableProps } from 'antd';

import Utils from '@/utils';

export interface InfiniteScrollTableProps<RecordType = any> extends TableProps<RecordType> {
  hasMore: boolean;
  loadMore: (page: number) => void;
  disabledLoad?: boolean;
  loadingMore?: boolean;
  loadingIndicator?: React.ReactNode;
  currentPage: number;
  scrollThreshold?: number;
}
export const InfiniteScrollTable = (props: InfiniteScrollTableProps) => {
  const [timer, setTimer] = useState<any>(null)
  const {
    loadingMore,
    hasMore,
    loadMore,
    disabledLoad,
    currentPage = 0,
    scrollThreshold = 0.8,
    loadingIndicator = (
      <div className="d-flex justify-content-center">
        <Spin spinning />
      </div>
    ),
    ...rest
  } = props;

  const renderLoadMoreSection = () => {
    if (disabledLoad === true || !hasMore || !loadMore) return <></>;
    if (loadingMore)
      return (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={props.columns?.length} rowSpan={2}>
            <div className="w-100">{loadingIndicator}</div>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    return <></>;
  };
  
  return (
    <Table
      size="small"
      {...rest}
      pagination={false}
      onScroll={e => {
        if (loadingMore || rest.loading || !hasMore) return;
        const scrollHeight = e.currentTarget.scrollHeight;
        const clientHeight = e.currentTarget.clientHeight;
        const scrollTop = e.currentTarget.scrollTop;
        
        const isEnd = scrollTop >= (scrollHeight - clientHeight) * scrollThreshold;
        
        if (isEnd) {
          clearTimeout(timer);
            const timeoutId = setTimeout(() => {
            // clearTimeout(timer);
            // const timeoutId = setTimeout(() => loadMore(currentPage + 1), 500);
            // setTimer(timeoutId);
            // loadMore(currentPage + 1)
            Utils.throttle(() => loadMore(currentPage + 1), 1000);
          }, 300)
          setTimer(timeoutId)
        }
      }}
      summary={pageData => {
        return (
          <>
            {props.summary && props.summary(pageData)}
            {renderLoadMoreSection()}
          </>
        );
      }}
    />
  );
};
