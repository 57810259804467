import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ProjectMemberType, ProjectInformationValue } from '@/common/define';
import { CreateLabelData } from '@/services/LabelService';
import { CreateProjectData, ProjectProgressResponse, ProjectResponse, TimeData } from '@/services/ProjectService';
import { ProjectTargetPagingResponse, ProjectTargetResponse } from '@/services/ProjectTargetService';

export enum ListView {
  LIST = 'LIST',
  THUMBNAIL = 'THUMBNAIL',
}
interface ProjectState {
  projectList: ProjectResponse[];
  projectMemberList: ProjectMemberType[];
  selectedProject?: ProjectResponse;
  createProjectCurrentStep: number;
  createProjectInformationValue: ProjectInformationValue | null;
  createProjectData: CreateProjectData | null;
  timeData: TimeData | null;
  createProjectResponse: any | null;
  projectAvatar?: string;
  projectTargets?: ProjectTargetPagingResponse;
  projectListView?: ListView;
  projectsProgress?: ProjectProgressResponse[];
  projectConfig?: TimeData | null;
}

const initialState: ProjectState = {
  projectList: [],
  projectMemberList: [],
  createProjectCurrentStep: 0,
  createProjectInformationValue: null,
  createProjectData: null,
  timeData: null,
  createProjectResponse: null,
  projectListView: ListView.THUMBNAIL,
  projectConfig: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectListView: (state, action) => {
      state.projectListView = action.payload;
    },
    setProjectList: (state, action: PayloadAction<any | undefined>) => {
      state.projectList = action.payload?.results || action.payload || [];
    },
    setSelectedProject: (state, action: PayloadAction<ProjectResponse | undefined>) => {
      state.selectedProject = action.payload;
      state.projectAvatar = action.payload?.avatar;
    },
    setCreateProjectCurrentStep: (state, action: PayloadAction<number>) => {
      state.createProjectCurrentStep = action.payload;
    },
    setCreateProjectInformationValue: (state, action: PayloadAction<ProjectInformationValue | null>) => {
      state.createProjectInformationValue = action.payload;
    },
    setProjectMemberList: (state, action: PayloadAction<ProjectMemberType[] | []>) => {
      state.projectMemberList = action.payload;
    },

    setCreateProjectData: (state, action: PayloadAction<CreateProjectData | null>) => {
      state.createProjectData = action.payload;
    },

    setCreateTimeData: (state, action: PayloadAction<TimeData | null>) => {
      state.timeData = action.payload;
    },

    setProjectAvatar: (state, action: PayloadAction<string | undefined>) => {
      state.projectAvatar = action.payload;
    },
    getProjectsRequest: (state, action) => {},

    createProjectRequest: (_state, _action: PayloadAction<CreateProjectData>) => {},

    setProjectConfig: (state, action) => {
      state.projectConfig = action.payload;
    },

    createProjectConfig: (_state, _action: PayloadAction<{ input: TimeData; projectId: number }>) => {},

    getProjectConfig: (_state, _action: PayloadAction<number>) => {},

    updateProjectConfig: (_state, _action: PayloadAction<{ input: TimeData; projectId: number }>) => {},

    saveProjectRequest: (_state, _action: PayloadAction<{ input: CreateProjectData; projectId: number }>) => {},

    setCreateProjectResponse: (state, action) => {
      state.createProjectResponse = action.payload;
      state.projectAvatar = action.payload?.avatar;
    },
    createProjectDefaultForPcvm: (_state, _action: PayloadAction<{ projectId: number }>) => {},

    createRootFolderRequest: (_state, _action: PayloadAction<{ projectId: number; folder: CreateLabelData }>) => {},

    createLabelRequest: (
      _state,
      _action: PayloadAction<{ projectId: number; label: CreateLabelData; parentId: string }>,
    ) => {},
    getProjectTargetsRequest: (_state, action: PayloadAction<{ projectId: number; body: string[] }>) => {},
    setProjectTargets: (state, _action) => {
      state.projectTargets = _action.payload;
    },
    saveProjectTargetsRequest: (
      _state,
      action: PayloadAction<{
        projectTargets: ProjectTargetResponse[];
        projectId: number;
        disciplines: string[];
      }>,
    ) => {},
    deleteProjectRequest: (_state, action: PayloadAction<any>) => {},
    // [30-7-2024] [thinh_dmp] [Implement #18899: get projects progress]
    getProjectsProgressRequest: (state, action) => {},
    setProjectsProgress: (state, action) => {
      state.projectsProgress = action.payload;
    },
  },
});

export const projectActions = projectSlice.actions;
export const projectReducer = projectSlice.reducer;
