import React, { useEffect, useState } from 'react';

import { ImportOutlined, ExportOutlined, PrinterOutlined, SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Pagination, Popover, Radio, Row, Select, Space, Spin, Table, Tooltip, Typography, Upload } from "antd";
import type { TableColumnsType, CheckboxProps, UploadFile, UploadProps, PaginationProps, RadioChangeEvent } from 'antd';
import { BaseType } from 'antd/es/typography/Base';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { PrintHTMLToPDF } from './PrintHelper';
import { CreateUpdatePipingWorkloadModalName, RemovingTrackingIssues, defaultPagingParams, formatDateDisplay, largePagingParams } from '@/common/define';
import { useWindowSize } from '@/hooks';
import { IssueService } from '@/services/IssueService';
import { getActiveMenu } from '@/store/app';
import { getAreas } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getIssueQueryParams, getIssues, getIssuesView, getTrackerId, issueActions } from '@/store/issue';
import { getLoading, startLoading, stopLoading } from '@/store/loading';
import { showModal } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { getWorkPackages } from '@/store/workPackage';
import Utils from '@/utils';

const { Text, Link } = Typography;
export interface Workload {
    id: number;
    material: string;
    plannedWeldingDI?: number;
    remainWeldingDI: number;
    requiredDailyW?: number;
    averageDIofDA: number;
    welderMP: number;
    actualDailyW?: number;
    actualWorkingDay?: number;
    costPerDI: number;
    status?: string;
    remainWCost?: number;
}

export interface RevisionWorkload {
    material: string;
    averageDIofDA: number;
    welderMP: number;
    welderMPTobe: number;
    remainWeldingDI: number;
    requiredDailyW: number;
    actualDailyW: number;
    actualWorkingDay: number;
    status: string;
}

interface PipingWorkloadTableProps {
    showRevision: boolean;
}

export const PipingWorkloadTable = ({ showRevision }: PipingWorkloadTableProps) => {
    const { t } = useTranslation('issue');
    const dispatch = useAppDispatch();
    const location = useLocation();
    const {refresh_token} = Utils.getPrameterToken(location.search);

    const selectedProject = useAppSelector(getSelectedProject());
    const [endDayProject, setEndDayProject] = useState(0);
    const view = useAppSelector(getIssuesView());
    const issues = useAppSelector(getIssues());
    const windowSize = useWindowSize();

    const [costPerDIs, setCostPerDIs] = useState<any[]>([]);
    const [averageDIofDAs, setAverageDIofDAs] = useState<any[]>([]);
    const [welderMPs, setwelderMPs] = useState<any[]>([]);
    const [showRevisionLocal, setShowRevision] = useState<boolean>(showRevision);

    const queryParams = useAppSelector(getIssueQueryParams());
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const issuesDeleting = useAppSelector(getLoading(RemovingTrackingIssues));

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    useEffect(() => {
        setShowRevision(showRevision);
    }, [showRevision])
    
    // eslint-disable-next-line
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 50,
    };

    useEffect(() => {
        console.log(selectedProject);
        if (selectedProject != null && new Date() < new Date(selectedProject?.endDate)) {
            var endDate = new Date(selectedProject?.endDate);
            console.log(endDate);
            if (endDate < new Date('01-09-1990')) {
                endDate = new Date();
            }
            var leftDays = calculateDifference(new Date(), endDate);
            setEndDayProject(leftDays > 0 ? leftDays : 0);
        } else {
            setEndDayProject(0);
        }
    }, [selectedProject]);

    useEffect(() => {
        setSelectedRowKeys([]);
    }, [issues]);
    
    const calculateDifference = (date1: Date, date2: Date) => {
        const differenceTime = Math.abs(date1.getTime() - date2.getTime());
        const differenceInDays = Math.ceil(differenceTime / (1000 * 60 * 60 * 24));
        return differenceInDays;
    };

    const columnWidth = 110;
    const getActualDaily = (record: any) => {
        let weilderMp = welderMPs.find(x => x.key == record.id)?.value || 0;
                let averageDIofDA = averageDIofDAs.find(x => x.key == record.id)?.value || 0;
                let actualDailyW = Math.round(weilderMp * averageDIofDA);
                return actualDailyW;
    }
    const getActualDailyW = (record: any) => {
        let weilderMp = welderMPs.find(x => x.key == record.id)?.value ?? 0;
                let averageDIofDA = averageDIofDAs.find(x => x.key == record.id)?.value ?? 0;
                let actualDailyW = Math.ceil(record.remainWeldingDI / (weilderMp * averageDIofDA));
                
                if (!(actualDailyW > 0)) {
                    actualDailyW = 0;
                }
                return actualDailyW;
    }
    const getRemainWCost = (record: any) => {
        var cost = costPerDIs.find(x => x.key == record.id)?.value ?? 0;
        var remainWCost = Math.round(cost * record.remainWeldingDI);
        return remainWCost;
    }
    const getStatus = (record: any) => {
        let weilderMp = welderMPs.find(x => x.key == record.id)?.value ?? 0;
                let averageDIofDA = averageDIofDAs.find(x => x.key == record.id)?.value ?? 0;
                let actualWorkingDay = Math.ceil(record.remainWeldingDI / (weilderMp * averageDIofDA));
                let status = "GOOD";
                let typeColor: BaseType = "success";
                if (actualWorkingDay > endDayProject) {
                    status = "LACK";
                    typeColor = "danger";
                }
        return {status, typeColor};
    }
    const getWelderMPT = (record: any) => {
        let averageDIofDA = averageDIofDAs.find(x => x.key == record.id)?.value ?? 0;
                let requiredDailyW = record.requiredDailyW ?? 0;
                let valueShow = Math.ceil(requiredDailyW / (averageDIofDA));
                return valueShow;
    }
    const getactualDailyW = (record: any) => {
        let weilderMp = welderMPs.find(x => x.key == record.id)?.value ?? 0;
                let averageDIofDA = averageDIofDAs.find(x => x.key == record.id)?.value ?? 0;
                let requiredDailyW = record.requiredDailyW ?? 0;
                let welderMPTobe = Math.ceil(requiredDailyW / averageDIofDA)
                let actualDailyW = Math.round(welderMPTobe * averageDIofDA);
                return actualDailyW;
    }
    const getActualWorkingDay = (record: any) => {
        let weilderMp = welderMPs.find(x => x.key == record.id)?.value ?? 0;
                let averageDIofDA = averageDIofDAs.find(x => x.key == record.id)?.value ?? 0;
                let requiredDailyW = record.requiredDailyW ?? 0;
                let welderMPTobe = Math.ceil(requiredDailyW / averageDIofDA)
                let actualDailyW = Math.round(welderMPTobe * averageDIofDA);
                let actualWorkingDay = Math.ceil(record.remainWeldingDI / actualDailyW);
                if (!(actualWorkingDay > 0)) {
                    actualWorkingDay = 0;
                }
                return actualWorkingDay;
    }
    const getStatus1 = (record: any) => {
        let averageDIofDA = averageDIofDAs.find(x => x.key == record.id)?.value ?? 0;
                let requiredDailyW = record.requiredDailyW ?? 0;
                let welderMPTobe = Math.ceil(requiredDailyW / averageDIofDA)
                let actualDailyW = Math.round(welderMPTobe * averageDIofDA);
                let actualWorkingDay = Math.ceil(record.remainWeldingDI / actualDailyW);
                let status = "GOOD";
                let typeColor: BaseType = "success";
                if (actualWorkingDay > endDayProject) {
                    status = "LACK";
                    typeColor = "danger";
                }
                return {status, typeColor};
            }
    const issuesColumns: TableColumnsType<any> = [
        {
            title: t('Material'),
            dataIndex: 'material',
            key: 'subject',
            width: 100,
        },
        {
            title: t('Planned Welding D/I'),
            dataIndex: 'plannedWeldingDI',
            key: 'planValue',
            width: columnWidth,
            align: 'center',
        },
        {
            title: t('Remain Welding (D/I)'),
            dataIndex: 'remainWeldingDI',
            key: 'remainWeldingDI',
            width: columnWidth,
            align: 'center',
        },
        {
            title: t('Required Daily W Vol (D/I)'),
            dataIndex: 'requiredDailyW',
            key: 'requiredDailyW',
            width: columnWidth,
            align: 'center',
        },
        {
            title: t('Average Daily W Vol (D/I)'),
            dataIndex: 'averageDIofDA',
            key: 'averageDIofDA',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => {
                return (
                    <Space>
                        <InputNumber placeholder="Basic usage" value={parseInt(averageDIofDAs.find(x => x.key == record.id)?.value || 0)} onChange={(value) => {
                            let newValue: any[] = [];
                            let temp = averageDIofDAs.map((x: any) => {
                                if (x.key == record.id) {
                                    x.value = value;
                                }
                                else {
                                    newValue.push({
                                        key: record.id,
                                        value: value
                                    })
                                }
                                return x;
                            });
                            setAverageDIofDAs(temp.concat(newValue));
                        }} />
                    </Space >
                );
            },
        },
        {
            title: t('Welder M/P'),
            dataIndex: 'welderMP',
            key: 'welderMP',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => {
                return (
                    <Space>
                        <InputNumber placeholder="Basic usage" value={welderMPs.find(x => x.key == record.id)?.value || 0} onChange={(value) => {
                            let newValue: any[] = [];
                            let temp = welderMPs.map((x: any) => {
                                if (x.key == record.id) {
                                    x.value = value;
                                }
                                else {
                                    newValue.push({
                                        key: record.id,
                                        value: value
                                    })
                                }
                                return x;
                            });
                            setwelderMPs(temp.concat(newValue));
                        }} />
                    </Space>
                );
            }
        },
        {
            title: t('Actual Daily W Vol D/I'),
            dataIndex: 'actualDailyW',
            key: 'actualDailyW',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => {
                let actualDailyW = getActualDaily(record);
                return (
                    <Space>
                        {actualDailyW == Infinity ? 0 : actualDailyW}
                    </Space>
                );
            },
        },
        {
            title: t('Actual working day'),
            dataIndex: 'actualWorkingDay',
            key: 'actualWorkingDay',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => {
                let actualDailyW = getActualDailyW(record);
                return (
                    <Space>
                        {actualDailyW}
                    </Space>
                );
            },
        },
        {
            title: t('Cost per D/I'),
            dataIndex: 'costPerDI',
            key: 'costPerDI',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => {
                return (
                    <Space>
                        <InputNumber placeholder="Basic usage" value={costPerDIs.find(x => x.key == record.id)?.value} onChange={(value) => {
                            let newValue: any[] = [];
                            let temp = costPerDIs.map((x: any) => {
                                if (x.key == record.id) {
                                    x.value = value;
                                }
                                else {
                                    newValue.push({
                                        key: record.id,
                                        value: value
                                    })
                                }
                                return x;
                            });
                            setCostPerDIs(temp.concat(newValue));
                        }} />
                    </Space>
                );
            },
        },
        {
            title: t('Status'),
            dataIndex: 'status',
            key: 'status',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => {
                let {status, typeColor} = getStatus(record);

                return (
                    <Space>
                        <Text type={typeColor}>{status}</Text>
                    </Space>
                );
            }
        },
        {
            title: t('Remain W Cost'),
            dataIndex: 'remainWCost',
            key: 'remainWCost',
            width: columnWidth,
            align: 'center',
            render: (value, record, index) => {
               let remainWCost=getRemainWCost(record);
                return (
                    <Space>
                        {remainWCost == Infinity ? 0 : remainWCost}
                    </Space>
                );
            },
        },
        {
            title: t('action'),
            width: 60,
            fixed: 'right',
            key: 'action',
            render: (_, record) => {
                return (
                    <Space>
                        {moreActions.map(action => (
                            <Tooltip placement="left" title={t(action.key)} >
                                <Button
                                    type="text"
                                    icon={action.icon}
                                    key={action.key}
                                    onClick={() => handleMoreActionClick(action.key, record)}
                                />
                            </Tooltip>
                        ))}
                    </Space>
                );
            },
        },
    ];

    const revisionColumns: TableColumnsType<any> = [
        {
            title: t('Material'),
            dataIndex: 'material',
            key: 'subject',
            width: 100,
        },
        {
            title: t('Average Daily W Vol (AS-IS)'),
            dataIndex: 'averageDIofDA',
            key: 'averageDIofDA',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => averageDIofDAs.find(x => x.key == record.id)?.value ?? 0,
        },
        {
            title: t('Welder M/P (AS-IS)'),
            dataIndex: 'welderMP',
            key: 'welderMP',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => welderMPs.find(x => x.key == record.id)?.value ?? 0,
        },
        {
            title: t('Welder M/P (TO BE)'),
            dataIndex: 'welderMPTobe',
            key: 'welderMPTobe',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => {
                let valueShow = getWelderMPT(record);
                return valueShow == Infinity ? 0 : valueShow;
            }
        },
        {
            title: t('Remain Welding (D/I)'),
            dataIndex: 'remainWeldingDI',
            key: 'remainWeldingDI',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => record.remainWeldingDI ?? 0,
        },
        {
            title: t('Required Daily W Vol (D/I)'),
            dataIndex: 'requiredDailyW',
            key: 'requiredDailyW',
            width: columnWidth,
            align: 'center',
        },
        {
            title: t('Actual Daily W Vol (D/I)'),
            dataIndex: 'actualDailyW',
            key: 'actualDailyW',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => {
                
                let actualDailyW = getactualDailyW(record);
                return (
                    <Space>
                        {actualDailyW == Infinity ? 0 : actualDailyW}
                    </Space>
                );
            },
        },
        {
            title: t('Actual working day'),
            dataIndex: 'actualWorkingDay',
            key: 'actualWorkingDay',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => {
                let actualWorkingDay = getActualWorkingDay(record);
                return (
                    <Space>
                        {actualWorkingDay}
                    </Space>
                );
            },
        },
        {
            title: t('Status'),
            dataIndex: 'status',
            key: 'status',
            width: columnWidth,
            align: 'center',
            render: (value: number, record: Workload) => {
                let {status,typeColor} = getStatus1(record);
                return (
                    <Space>
                        <Text type={typeColor}>{status}</Text>
                    </Space>
                );
            }
        },
    ];

    const isFetching = useAppSelector(getLoading('GetIssuesByTracker'));

    const trackerId = useAppSelector(getTrackerId());
    const areas = useAppSelector(getAreas());
    const workPackages = useAppSelector(getWorkPackages());
    const activeMenu = useAppSelector(getActiveMenu());

    const [exportForm] = Form.useForm();

    const [areaOptions, setAreaOptions] = useState<{ label: string, value: number }[]>([]);
    const [selectArea, setSelectArea] = useState(-1);
    const [selectDate, setSelectDate] = useState(dayjs());
    
    const [exportAreaOptions, setExportAreaOptions] = useState<{ label: string, value: number }[]>([]);

    const [workPackageOptions, setWorkPackageOptions] = useState<{ label: string, value: number }[]>([]);
    
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const isImporting = useAppSelector(getLoading('ImportingIssue'));
    const isExporting = useAppSelector(getLoading('ExportingIssue'));

    useEffect(() => {
        const options = (areas?.results || []).map(area => ({
            label: area.name,
            value: area.id,
        }));
        setExportAreaOptions([{label: 'All', value: -1}].concat(options));
    }, [areas]);

    useEffect(() => {
        const options = (workPackages?.results || []).map(workPackage => ({
            label: workPackage.name,
            value: workPackage.id,
        }));
        setWorkPackageOptions([{label: 'All', value: -1}].concat(options));
    }, [workPackages]);

    const props: UploadProps = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([...fileList, file]);
            dispatch(issueActions.importFileRequest({ projectId: selectedProject?.id, importType: importType, file }));
            return false;
        },
        fileList,
    };

    const [importType, setImportType] = useState(0);
    const importTypeList = [
        { key: 'new', label: t('new'), value: 0 },
        { key: 'edit', label: t('edit'), value: 1 }
    ];
    const [openImportFileType, setOpenImportFileType] = useState(false);

    const onChangeImportType = (e: RadioChangeEvent) => {
        setImportType(e.target.value);
    };

    const handleRemoveIssues = (issueIds: React.Key[]) => {
        dispatch(issueActions.deleteIssuesRequest({ issueIds, projectId: selectedProject?.id, trackerId: trackerId }));
    };

    const confirmRemoveIssues = () => {
        if (selectedRowKeys) {
            Modal.confirm({
                title: t('remove'),
                content: (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `Are you sure to remove ${selectedRowKeys.length} record${selectedRowKeys.length > 1 ? 's' : ''}`,
                        }}
                    />
                ),
                closable: true,
                onOk: close => {
                    handleRemoveIssues(selectedRowKeys);
                    close();
                },
            });
        }
    };

    const importFileRender = () => {
        return (
            <div style={{ width: 200 }}>
                <Radio.Group value={importType} onChange={onChangeImportType}>
                    <Row>
                        {importTypeList.map(item => (
                            <Col span={60} key={item.key}>
                                <Radio value={item.value}>{item.label}</Radio>
                            </Col>
                        ))}
                    </Row>
                </Radio.Group>
                <Divider style={{ margin: '10px 0' }} />
                <Row>
                    <Space style={{ flex: 1 }}>
                        <Button onClick={() => setOpenImportFileType(false)} type="text">
                            Cancel
                        </Button>
                    </Space>
                    <Upload {...props} showUploadList={false} maxCount={1} accept=".xlsx,.xls,.csv">
                        <Button type="primary" onClick={() => setOpenImportFileType(false)}> {t('Import')} </Button>
                    </Upload>
                </Row>
            </div >
        );
    };

    const [openExportFile, setOpenExportFile] = useState(false);

    const exportFileRender = () => {
        return (
            <div style={{ width: 250 }}>
                <Form 
                    form={exportForm} 
                    layout='vertical' 
                    onFinish={onExportFileHandle}
                    initialValues={{
                        exportArea: -1,
                        exportWorkPackage: -1,
                        fileName: `Issue (${activeMenu?.label})`
                    }}
                >
                    <Row>
                        <Form.Item name={'exportArea'}>
                            <Select options={exportAreaOptions} style={{ width: 250 }}/>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Form.Item name={'exportWorkPackage'}>
                            <Select options={workPackageOptions} style={{ width: 250 }}/>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Form.Item name={'fileName'} style={{ width: '100%' }}>
                            <Input addonAfter={'.xlsx'} />
                        </Form.Item>
                    </Row>
                    <Divider style={{ margin: '10px 0' }} />
                    <Row>
                        <Space style={{ flex: 1 }}>
                            <Button onClick={() => setOpenExportFile(false)} type="text">
                                Cancel
                            </Button>
                        </Space>
                        <Button type="primary" onClick={exportForm.submit}>{t('Export')}</Button>
                    </Row>
                </Form>
            </div>
        );
    };

    const onExportFileHandle = (values: any) => {
        const { exportArea, exportWorkPackage, fileName } = values;
        setOpenExportFile(false)
        if (selectedProject && trackerId) {
            dispatch(startLoading({key: 'ExportingIssue'}));

            const downloadedFileName = fileName ? `${fileName}.xlsx` : 'issues.xlsx';
            IssueService.Get.exportFile(selectedProject?.id, {
                search: { 
                    trackerId: trackerId.toString(), 
                    areaId: exportArea, 
                    workpackageId: exportWorkPackage,
                    startDate:  selectDate.toISOString()
                }
            }).subscribe((issueFile: any) => {
                if (issueFile != null) {
                    const url = window.URL.createObjectURL(new Blob([issueFile]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', downloadedFileName);
                    document.body.appendChild(link);
                    link.click();
                    Utils.successNotification(t('Export successfully'))
                }
            },
            err => {
                Utils.errorHandling(err);
                dispatch(stopLoading({key: 'ExportingIssue'}));
            },
            () => {
                dispatch(stopLoading({key: 'ExportingIssue'}));
            });
        }
        
    };

    useEffect(() => {
        const options = (areas?.results || []).map(area => ({
            label: area.name,
            value: area.id,
        }));
        setAreaOptions(options);
        if (selectArea == -1) {
            if (options.length > 0) {
                setSelectArea(options[0].value);
            }
            else {
                setAreaOptions([{
                    label: 'All',
                    value: -1
                }]);
            }
        }
    }, [areas]);

    useEffect(() => {
        if (selectArea != null && selectedProject != null) {
            const tzOffset = new Date().getTimezoneOffset();
            dispatch(
                issueActions.getIssuesByTrackerRequest({
                    projectId: selectedProject?.id,
                    trackerId: trackerId,
                    queryParams: { ...largePagingParams, areaId: selectArea, startDate: selectDate.subtract(tzOffset, 'minutes').toISOString() },
                }),
            );
        }
    }, [selectArea, selectDate])

    const [workloads, setWorkloads] = useState<Workload[]>([]);

    useEffect(() => {
        let issueList = issues?.results || [];

        var newWorkloads: Workload[] = [];
        issueList.forEach(issue => {
            var plannedWeldingDI = issue.issueTargets.find(x => x.targetDim.code == 'PlannedWeldingDI');
            var remainWeldingDI = plannedWeldingDI ? plannedWeldingDI.planValue - plannedWeldingDI.actualValue : 0;
            var requiredDailyW = Math.ceil(remainWeldingDI / endDayProject);
            var averageDIofDA = Number(issue.attributes.find(x => x.code == 'DailyCapacity')?.value) ?? 0;
            var welderMP = Number(issue.attributes.find(x => x.code == 'WelderMP')?.value) ?? 0;
            var costPerDI = plannedWeldingDI?.costPerValue ?? 0;

            newWorkloads.push({
                id: issue.id,
                material: issue.subject,
                plannedWeldingDI: plannedWeldingDI?.planValue ?? 0,
                remainWeldingDI: remainWeldingDI,
                requiredDailyW: requiredDailyW,
                averageDIofDA: averageDIofDA,
                welderMP: welderMP,
                costPerDI: costPerDI,
            });
        });
        setWorkloads(newWorkloads);

        var temp = newWorkloads.map(issue => {
            return {
                key: issue.id,
                value: issue.averageDIofDA
            };
        });
        setAverageDIofDAs(temp);
        temp = newWorkloads.map(issue => {
            return {
                key: issue.id,
                value: issue.costPerDI
            };
        });
        setCostPerDIs(temp);
        temp = newWorkloads.map(issue => {
            return {
                key: issue.id,
                value: issue.welderMP
            };
        });
        setwelderMPs(temp);
    }, [issues]);

    const moreActions = [
        {
            key: 'edit',
            label: t('edit'),
            icon: <EditOutlined style={{ color: '#1890ff' }} />,
        },
    ];

    const handleMoreActionClick = (key: any, record: any) => {
        console.log(key, record);
        if (issues) editIssue(issues.results.find(x => x.id == record.id));
    };

    const editIssue = (issue: any) => {
        dispatch(issueActions.setSelectedIssue(issue));
        dispatch(showModal({ key: CreateUpdatePipingWorkloadModalName }));
    };


    const onChangeArea = (type: any) => {
        setSelectArea(type);
    };

    const showTotal: PaginationProps['showTotal'] = (total, range) => `${range[0]}-${range[1]} of ${total} items`;
    const onPagingChange = (page: number, pageSize: number) => {
        dispatch(
            issueActions.getIssuesByTrackerRequest({
                projectId: selectedProject?.id,
                trackerId: trackerId,
                queryParams: { ...queryParams, page, pageSize, areaId: selectArea, startDate: selectDate.toISOString()  },
            }),
        );
    };
    const printPipingWorkload = () => {
        const dataPrint = {
            ProjectName: selectedProject?.name,
            Area: areaOptions.find(x => x.value == selectArea)?.label,
            CalculationBaseDate: selectDate.format('DD/MM/YYYY'),
            ProjectLeftDay: endDayProject,
            RemainPipingWorkload: '',
            RevisionPlan: '',
        };
        for(let item of workloads) {
            let {status, typeColor} = getStatus(item)
            dataPrint['RemainPipingWorkload'] += `
            <tr>
            <td>${item.material}</td>
            <td>${item.plannedWeldingDI}</td>
            <td>${item.remainWeldingDI}</td>
            <td>${item.requiredDailyW}</td>
            <td>${averageDIofDAs.find(x => x.key == item.id)?.value}</td>
            <td>${welderMPs.find(x => x.key == item.id)?.value}</td>
            <td>${getActualDaily(item)}</td>
            <td>${getActualDailyW(item)}</td>
            <td>${costPerDIs.find(x => x.key == item.id)?.value}</td>
            <td>${status}</td>
             <td>${getRemainWCost(item)}</td>
            </tr>
            `;
        }   
        for(let item of workloads) {
            let {status, typeColor} = getStatus1(item)
            dataPrint['RevisionPlan'] += `
            <tr>
            <td>${item.material}</td>
            <td>${averageDIofDAs.find(x => x.key == item.id)?.value ?? 0}</td>
            <td>${welderMPs.find(x => x.key == item.id)?.value ?? 0}</td>
            <td>${getWelderMPT(item)}</td>
            <td>${item.remainWeldingDI ?? 0}</td>
            <td>${item.requiredDailyW}</td>
            <td>${getactualDailyW(item)}</td>
            <td>${getActualWorkingDay(item)}</td>
            <td>${status}</td>
            </tr>
            `;
        }   
        PrintHTMLToPDF(dataPrint);
    }
    return (
        <Spin spinning={isFetching}>
            <Row style={{ padding: 10 }} wrap gutter={[10, 10]}>
                <Space style={{ flex: 1, minWidth: 350 }} wrap>
                    <DatePicker style={{ width: '100%' }} defaultValue={dayjs()} format={formatDateDisplay} onChange={(value) => {
                        if (value) setSelectDate(value);
                    }} />
                    <div>
                        <Typography.Text style={{ fontWeight: '400', fontSize: '14px', marginInline: '10px' }}>
                            {t('Area Priority')}
                        </Typography.Text>
                        <Select options={areaOptions} onChange={onChangeArea} style={{ width: 160 }} value={selectArea} />
                    </div>
                </Space>
                <Space wrap>
                    {
                        refresh_token &&
                        <Typography.Text style={{ fontWeight: '400', fontSize: '14px', marginLeft: '10px', wordBreak: 'keep-all' }}>
                            {t('Project left ') + endDayProject + t(' days')}
                        </Typography.Text>
                    }
                    { refresh_token &&
                        <Button type="primary" onClick={() => {setShowRevision(!showRevisionLocal)}}>
                        {showRevisionLocal ? t('Hide Revision Plan') : t('Show Revision Plan')}
                        </Button>
                    }
                    <Popover
                        content={importFileRender()}
                        trigger="click"
                        placement="bottomLeft"
                        arrow={false}
                        open={openImportFileType}
                        onOpenChange={setOpenImportFileType}
                    >
                        <Button loading={isImporting} icon={<ImportOutlined />}>
                            {t('Import')}
                        </Button>
                    </Popover>
                    <Popover
                        content={exportFileRender()}
                        trigger="click"
                        placement="bottomLeft"
                        arrow={false}
                        open={openExportFile}
                        onOpenChange={setOpenExportFile}
                    >
                        <Button loading={isExporting}  icon={<ExportOutlined />}>{t('Export')}</Button>
                    </Popover>
                    <Button icon={<PrinterOutlined />} onClick={() => {
                        printPipingWorkload();
                    }}>
                            {t('Print')}
                        </Button>
                    {
                        !!selectedRowKeys.length && <>
                        <Divider type='vertical' style={{ borderInlineStart: '1px solid rgba(0, 0, 0, 0.16)'}}/>
                        <Space direction='horizontal'>
                            <Typography.Text>
                                {`${selectedRowKeys.length} record${selectedRowKeys.length > 1 ? 's' : ''} selected`}
                            </Typography.Text>
                            <div>
                                <Button danger loading={issuesDeleting} onClick={confirmRemoveIssues}>Delete</Button>
                            </div>
                        </Space>
                        </> 
                    }
                </Space>
            </Row>
            <div>
                {view === 'List' && (
                    <div style={{ margin: '0 10px' }}>
                        <Table
                            rowKey={record => record.id}
                            virtual
                            dataSource={workloads}
                            rowSelection={rowSelection}
                            expandable={{
                                defaultExpandAllRows: true,
                            }}
                            columns={issuesColumns}
                            size="small"
                            scroll={{ x: 1330, y: (windowSize[1] / 2) - 200 }}
                            pagination={false}
                            sticky={{ offsetHeader: 39 }}
                        />
                        {/* <div style={{ marginTop: 10, textAlign: 'right' }}>
                            <Pagination
                                total={issues?.queryCount || 0}
                                current={issues?.page || 1}
                                pageSize={issues?.pageSize || 20}
                                onChange={onPagingChange}
                                // showSizeChanger
                                responsive
                                showTotal={showTotal}
                            />
                        </div> */}
                    </div>
                )}
                {showRevisionLocal && (
                    <div style={{ margin: '0 10px' }}>
                        <Divider />
                        <Table
                            rowKey={record => record.id}
                            virtual
                            dataSource={workloads}
                            expandable={{
                                defaultExpandAllRows: true,
                            }}
                            columns={revisionColumns}
                            size="small"
                            scroll={{ x: 1330, y: (windowSize[1] / 2) - 150 }}
                            pagination={false}
                            sticky={{ offsetHeader: 39 }}
                        />
                    </div>
                )}

            </div>
        </Spin>
    );
}