import { useEffect, useState } from 'react';

import { Button, Flex, Modal, Popconfirm, Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CreateUpdateTrackingIssue } from './CreateUpdateTrackingIssue';
import { IssueHeader } from './IssueHeader';
import { IssuesByTrackingTable } from './IssuesByTrackingTable';
import { CreateUpdateTrackingIssueModalName, defaultPagingParams, largePagingParams } from '@/common/define';
import { getEnvVars } from '@/environment';
import { getAuthenticated } from '@/store/app';
import { areaActions } from '@/store/area';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTracker, issueActions } from '@/store/issue';
import { getModalVisible } from '@/store/modal';
import { getSelectedProject } from '@/store/project';
import { workPackageActions } from '@/store/workPackage';

const { apiUrl } = getEnvVars();
var count = 0;
var eventSource: EventSource | null = null;
export const IssuePiping = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('BasedData');
  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateTrackingIssueModalName));
  const selectedProject = useAppSelector(getSelectedProject());
  const tracker = useAppSelector(getTracker());
  const auth = useAppSelector(getAuthenticated());
  const code = 'PipingTask';
  useEffect(() => {
    dispatch(issueActions.setIssues([]));
    // eslint-disable-next-line
    if (auth) dispatch(issueActions.getTrackerRequest({ companyId: auth?.companyId, queryParams: largePagingParams }));
  }, []);
  const [isSynchronizing, setSynchronizing] = useState(false);
  const [processPercent, setProcessPercent] = useState(0);
  const GetSynchronizing = () => {
    const url = `${apiUrl}/api/Pcvm/sync-progress?projectId=${selectedProject?.id}`;
    const source = new EventSource(url);
    count = 0;
    source.onopen = () => {
      console.log('Connection to server opened.');
    };

    source.onmessage = event => {
      let response = JSON.parse(event.data);
      let percent = Number(response.Progress ?? '0');
      setProcessPercent(percent);
      if (percent >= 0 && percent < 100) {
        setSynchronizing(true);
      } else if (percent === 100) {
        eventSource?.close();
        setSynchronizing(false);
      } else {
        eventSource?.close();
        setSynchronizing(false);
      }
    };

    source.onerror = event => {
      count++;
    };

    if (eventSource != null) {
      eventSource.close();
      eventSource = null;
    }
    eventSource = source;
  };
  useEffect(() => {
    const trackerIds = tracker?.results ?? [];
    if (trackerIds.length > 0) {
      var trackerItem = trackerIds.find(t => t.code == code);
      dispatch(issueActions.setTrackerId(trackerItem?.id));
      if (selectedProject != null && trackerItem != null) {
        dispatch(
          issueActions.getAttributesByTrackerRequest({
            trackerId: trackerItem.id,
            queryParams: {
              ...largePagingParams,
            },
          }),
        );
        dispatch(
          issueActions.getIssuesByTrackerRequest({
            projectId: selectedProject.id,
            trackerId: trackerItem.id,
            queryParams: defaultPagingParams,
          }),
        );
        dispatch(
          issueActions.getTargetsByTrackerRequest({
            queryParams: { ...largePagingParams, trackerId: trackerItem.id, projectId: -1 },
          }),
        );
      }
    }
  }, [tracker]);

  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    dispatch(
      areaActions.getAreasRequest({
        params: { ...largePagingParams },
        projectId: selectedProject.id,
      }),
    );
    dispatch(
      workPackageActions.getWorkPackagesRequest({
        params: { ...largePagingParams },
        projectId: selectedProject.id,
      }),
    );
    if (selectedProject && !eventSource) {
      GetSynchronizing();
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  return (
    <>
      <IssueHeader />
      <IssuesByTrackingTable code={code} atrrSortCols={['LineNo', 'IsoNo', 'SpoolNo']} />
      {isModalOpen && <CreateUpdateTrackingIssue code={code} />}
      {isSynchronizing && (
        <Modal
          onOk={() => {
            navigate('/');
          }}
        >
          {isSynchronizing && (
            <Flex
              gap="small"
              align="center"
              style={{
                justifyContent: 'center',
                width: 280,
              }}
            >
              <Progress width={100} percent={processPercent} />
            </Flex>
          )}
        </Modal>
      )}
    </>
  );
};
